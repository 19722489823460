import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "features/configure";
import { Grid, Chip } from "@mui/material";
import UserInfoRoot from "./UserInfoRoot";
import _, { get, map } from "lodash";
import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Comment from "./components/Comment";
import { SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import Chat from "./Chat";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import TicketDrawer from "components/TicketDetail/TicketDrawer";
import { useFetchTeamMember } from "./redux/hooks";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { Loading } from "features/common";
import Channel from "./components/Channel";
import { useFetchUserInfo } from "./redux/fetchUserInfo";
import Box from "@mui/material/Box";

import {
  StyledTicketDetailContainer,
  StyledMessageInfoContainer,
  StyledChatAreaContainer,
} from "./TicketDetail.styles";

import Collabrators from "./components/Collabrators";
import { parseToOptionFormat } from "./settings/Macros/CreateMacroModal/dropdown.utils";
import { alertServerError, parseCustomDomainUrl } from "features/helpers/utils";
import TicketTypeLabel from "./components/TicketTypeLabel";
import { useIntl } from "react-intl";
import useGetPagePermission from "hooks/useGetPagePermission";
import TicketTag from "./components/TicketTag";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import Back from "./components/Back";
const useStyles = makeStyles((theme) => {
  return {
    messageDetail: {
      background: "#FFFFFF",
      height: "calc(100vh - 80px)",
      border: "1px solid #D6D6D6",
      overflow: "hidden auto",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
    sideBarHeader: {
      fontSize: 12,
      fontWeight: 500,
      color: "#A5A5A5",
      display: "flex",
      padding: "12px 10px",
      borderBottom: "1px solid #E6E6E6",
      marginBottom: 16,
      alignItems: "center",
    },
    inboxDescriptionLabel: {
      textAlign: "left",
      fontWeight: 400,
      fontSize: "12px",
      color: "#777",
      marginBottom: 9,
    },
    avatar: {
      width: 22,
      height: 22,
      marginRight: 4,
      borderRadius: 40,
    },
    selected: {
      "&.Mui-selected": {
        backgroundColor: `#F7F1FE`,
        "&:hover": {
          backgroundColor: `#F7F1FE`,
        },
      },
    },
    field: {
      marginBottom: 12,
    },
  };
});

const TicketDetail = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [entityName, isCustomDomain] = useOutletContext();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const { ticketId } = useParams();
  const [comment, setComment] = useState("");
  const [selectedTicket, setSelectedTicket] = useState("");
  const { auth, userData } = useConnectWallet();
  const { memberList, memberByTicketList } = useFetchTeamMember();
  const { fetchUserInfo } = useFetchUserInfo();
  const role = _.get(userData, "role", "Assistant");
  const userAddress = _.get(userData, "address", "");
  const selectedUserAddress = _.get(selectedTicket, "userWallet.address");
  const userId = _.get(selectedTicket, "user._id");
  const { formatMessage } = useIntl();
  const discordId = _.get(
    selectedTicket,
    "user.mostRecentDiscordID.handle",
    ""
  );
  const navigate = useNavigate();
  const classes = useStyles();

  const fetchTicketDetailById = async () => {
    if (!auth) return;
    try {
      let ticketData = await axios.get(
        `${apiUrl}/api/ticket/${ticketId}?entityName=${entityName}`
      );
      setSelectedTicket(ticketData.data);
      setComment(_.get(ticketData, "data.comment"));
    } catch (err) {
      dispatch(alertServerError(err));
      navigate(parseCustomDomainUrl(isCustomDomain, entityName, "/error"));
    }
  };
  const dispatchSnackbar = useDispatchSnackbar();

  const applyToTicket = async (value) => {
    try {
      let ticketData = await axios.post(
        `${apiUrl}/api/manage/tags/${entityName}/ticket/${ticketId}/apply`,
        {
          tagIds: value,
        }
      );
    } catch (err) {
      dispatchSnackbar({ err });
    }
  };

  const tagValue = parseToOptionFormat(get(selectedTicket, "tags", []));
  async function handleChooseTag(newValue) {
    setSelectedTicket({
      ...selectedTicket,
      tags: map(newValue, (o) => {
        return { _id: o.value, value: o.label };
      }),
    });

    await applyToTicket(map(newValue, "_id"));
  }

  useEffect(() => {
    fetchTicketDetailById();
    const id = setInterval(async () => {
      await fetchTicketDetailById();
    }, 15000);
    return () => clearInterval(id);
  }, [ticketId, auth]);

  useEffect(() => {
    if (userId) {
      fetchUserInfo({ entityName, userId });
    }
  }, [entityName, userId]);
  const tagTeam = _.map(selectedTicket.tagTeam, "_id");

  const pagePermission = useGetPagePermission();
  const { canEdit, readonly } = pagePermission("tickets");
  const userCanEdit =
    canEdit || selectedTicket.isAssigned || tagTeam.includes(userAddress);

  const renderUserInfo = () => {
    return (
      <div
        style={{
          background: "#F8F8F8",
          padding: 0,
          overflowX: "hidden",
          height: "100%",
          overflowY: "auto",
          position: "relative",
          overflowY: "scroll",
        }}
      >
        {!selectedUserAddress && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255,255,255,0.9)",
              zIndex: 100,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("assets/img/boxOpen.svg").default}
              style={{ width: 40, height: 40 }}
            />
            <div
              style={{
                color: "#777777",
                fontWeight: 500,
                fontSize: 14,
                marginTop: 7,
              }}
            >
              {formatMessage({ id: "ticketDrawer.did.empty" })}
            </div>
          </div>
        )}
        <UserInfoRoot
          selectedUserAddress={selectedUserAddress}
          selectedUserId={userId}
          entityName={entityName}
        />
      </div>
    );
  };

  const renderAssignee = () => {
    const assignee = _.get(selectedTicket, "team._id", "");
    return (
      <div>
        <div className={classes.inboxDescriptionLabel}>
          {formatMessage({ id: "ticketDetail.ticketInfo.assignee" })}
        </div>
        <div className={classes.field}>
          {readonly ? (
            <Chip
              label={_.get(
                _.find(memberByTicketList, {
                  _id: assignee,
                }),
                "name",
                "N/A"
              )}
            />
          ) : (
            <CustomDropdown
              border
              data={_.map(memberByTicketList, (data) => {
                return {
                  label: <span>{data.name || "No Name"}</span>,
                  value: data._id,
                };
              })}
              fullWidth
              size={SIZE.LARGE}
              placeholder={"N/A"}
              value={_.get(selectedTicket, "team._id", "")}
              onSelect={async (e) => {
                let team = _.find(memberByTicketList, {
                  _id: e.target.value,
                });
                await axios.post(`${apiUrl}/api/manage/ticket/assign`, {
                  entityName,
                  ticketId: _.get(selectedTicket, "_id", ""),
                  team: team._id,
                });
                setSelectedTicket({
                  ...selectedTicket,
                  team: team,
                });
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderCollaborators = () => {
    const isAssignee =
      userAddress == _.get(selectedTicket, "team.address", "N/A");
    if (memberList.length == 0 || (!isAssignee && tagTeam.length == 0)) return;
    return (
      <>
        <div className={classes.inboxDescriptionLabel}>
          {formatMessage({ id: "ticketDetail.ticketInfo.collaborators" })}
        </div>
        <div className={classes.field}>
          <Collabrators
            editable={isAssignee}
            entityName={entityName}
            ticketId={_.get(selectedTicket, "_id", "")}
            selectedMembers={_.get(selectedTicket, "tagTeam", [])}
          />
        </div>
      </>
    );
  };

  const renderView = () => {
    if (!auth) {
      return <Loading open={true} fullScreen={true} />;
    }
    return (
      <StyledTicketDetailContainer>
        <Box className="container-inside">
          <Loading open={!auth || !selectedTicket} fullScreen={true} />
          <div>
            <Back
              text={formatMessage({
                id: "ticketDetail.ticketInfo.ticketList",
              })}
            />
          </div>
          <div>
            <Grid
              container
              alignItems="stretch"
              direction="row"
              justifyContent="center"
              style={{ position: "relative" }}
            >
              <StyledMessageInfoContainer>
                <div className={classes.messageDetail}>
                  <div className={`${classes.sideBarHeader}`}>
                    <i className="meta-crm-icon-ic_ticketInfo font-size-14 mgr4" />{" "}
                    {formatMessage({
                      id: "ticketDetail.ticketInfo.title",
                    })}
                  </div>
                  <div style={{ padding: "0 10px" }}>
                    <div className={classes.inboxDescriptionLabel}>
                      {formatMessage({
                        id: "ticketDetail.ticketInfo.ticketTitle",
                      })}
                      :
                    </div>
                    <div className={classes.field}>{selectedTicket.title}</div>
                    <div className={classes.inboxDescriptionLabel}>
                      {formatMessage({ id: "ticketList.filter.Issue" })}:
                    </div>
                    <div className={classes.field}>
                      {selectedTicket && (
                        <TicketTypeLabel
                          ticketType={selectedTicket.type}
                          edit={userCanEdit}
                          callback={async (newValue) => {
                            await axios.post(
                              `${apiUrl}/api/ticket/updateTicketType`,
                              {
                                entityName,
                                ticketId: _.get(selectedTicket, "_id", ""),
                                ticketTypeId: newValue,
                              }
                            );
                            fetchTicketDetailById();
                          }}
                        />
                      )}
                    </div>
                    <div className={classes.inboxDescriptionLabel}>
                      {formatMessage({ id: "ticketList.filter.channel" })}:
                    </div>
                    <div className={classes.field}>
                      <Channel ticket={selectedTicket} showLabel={true} />
                    </div>
                    <div className={classes.inboxDescriptionLabel}>
                      {formatMessage({
                        id: "ticketDetail.ticketInfo.ticketTag",
                      })}
                      :
                    </div>
                    <div className={classes.field}>
                      <TicketTag
                        canEdit={userCanEdit}
                        tags={tagValue}
                        handleChooseTag={handleChooseTag}
                      />
                    </div>
                  </div>
                  <div className={`${classes.sideBarHeader}`}>
                    <i className="meta-crm-icon-ic_collaboration font-size-14 mgr4" />{" "}
                    {formatMessage({
                      id: "ticketDetail.ticketInfo.collaboration",
                    })}
                  </div>
                  <div style={{ padding: "0 10px" }}>
                    {renderAssignee()}
                    {renderCollaborators()}

                    <div
                      className={classes.inboxDescriptionLabel}
                      style={{ marginTop: 12 }}
                    >
                      {formatMessage({
                        id: "ticketDetail.ticketInfo.comment",
                      })}
                    </div>
                    <Comment
                      entityName={entityName}
                      selectedTicket={selectedTicket}
                      row={false}
                      comment={comment}
                      setComment={setComment}
                    />
                  </div>
                </div>
              </StyledMessageInfoContainer>
              <StyledChatAreaContainer>
                <div
                  className={classes.messageDetail}
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Chat
                    selectedTicket={selectedTicket}
                    discordId={discordId}
                    entityName={entityName}
                    setSelectedTicket={setSelectedTicket}
                    userCanEdit={userCanEdit}
                  />
                </div>
              </StyledChatAreaContainer>
              <TicketDrawer
                entityName={entityName}
                selectedUserId={userId}
                selectedTicket={selectedTicket}
                selectedUserAddress={selectedUserAddress}
                computer={computer}
                renderUserInfo={renderUserInfo}
                userCanEdit={userCanEdit}
              />
              {/* <Grid item xs={12} md={4}>
                      {renderUserInfo()}
                    </Grid> */}
            </Grid>
          </div>
        </Box>
      </StyledTicketDetailContainer>
    );
  };
  return <>{renderView()}</>;
};

export default TicketDetail;

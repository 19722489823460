import { Box } from "@mui/material";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import React, { useContext, memo, useEffect } from "react";
import { CampaignCreationContext } from "./context/CampaignCreationProvider";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import { useForm } from "react-hook-form";
import { debounce, get, isEmpty } from "lodash-es";
import { useOutletContext } from "react-router";
import { useIntl } from "react-intl";
import { Loading } from "features/common";

const CampaignStep1 = ({ step, setCanDraft }) => {
  const { stepData, setStepValues, handleSetIsValid, loading } = useContext(
    CampaignCreationContext
  );
  const { formatMessage } = useIntl();
  const [entityName] = useOutletContext();
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo, isValid },
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setStepValues({ [name]: value });
  };

  useEffect(() => {
    handleSetIsValid(isValid);
  }, [isValid]);

  useEffect(() => {
    if (!isEmpty(stepData)) {
      reset(stepData);
    }
  }, [stepData]);

  return (
    <>
      <Loading open={loading} fullScreen={true} />
      <Box>
        <Box className="title" mb={1}>
          {formatMessage({ id: "campaignBuild.firstStep.title" })}{" "}
          <span style={{ color: "#7B61FF" }}> {step}/3</span>
        </Box>
        <Box className="description" mb={"22px"}>
          {formatMessage({ id: "campaignBuild.firstStep.description" })}
        </Box>
      </Box>

      <StyledWhiteContainer>
        {/* name input */}
        <Box sx={{ marginBottom: "20px" }}>
          <InputField
            title={formatMessage({ id: "campaignBuild.info.campaignName" })}
            isRequired={true}
            placeholder={formatMessage({
              id: "campaignBuild.firstStep.campaignName.placeholder",
            })}
            name="name"
            value={stepData.name}
            onChange={handleFieldChange}
            errors={get(errorsInfo, "name.message")}
            control={control}
            maxLength={50}
            rules={{
              required: {
                value: true,
                message:
                  formatMessage({ id: "global.commonWord.name" }) +
                  formatMessage({ id: "global.error.required" }),
              },
            }}
          />
          <p className="infoText startRow">
            <i className="meta-crm-icon-ic_info font-size-12" />
            <span style={{ marginLeft: "4px" }}>
              {formatMessage({ id: "campaignBuild.campaignName.tooltip" })}
            </span>
          </p>
        </Box>
        {/* description input */}
        <Box>
          <InputField
            title={formatMessage({
              id: "campaigns.header.campaignDescription",
            })}
            multiline={true}
            rows={8}
            maxLength={1000}
            placeholder={formatMessage({ id: "global.column.description" })}
            name="description"
            value={stepData.description}
            onChange={handleFieldChange}
          />
        </Box>
      </StyledWhiteContainer>
    </>
  );
};

export default memo(CampaignStep1);

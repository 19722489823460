import React, { memo, useContext, useEffect, useState } from "react";
import { PerformanceContext } from "./context/PerformanceProvider";
import { useIntl } from "react-intl";
import { Box, useTheme } from "@mui/material";
import {
  Button,
  VARIANT,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/Button";

import useNavigateEntity from "hooks/useNavigateEntity";
import useMarketModule from "hooks/useMarketModule";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import _ from "lodash";
import MetaCRMDateRange from "components/DateRange/MetaCRMDateRange";
import { useSelector } from "react-redux";
import { selectDemoSite } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import moment from "moment";
function PerformanceHeader({ objectiveData, isShowObjective }) {
  const { startDate, setStartDate, endDate, setEndDate, setSearch } =
    useContext(PerformanceContext);
  const [input, setInput] = useState("");
  const theme = useTheme();
  const { attributionConnect } = useMarketModule();
  const { navigateEntity } = useNavigateEntity();
  const { formatMessage } = useIntl();
  const isDemoSite = useSelector(selectDemoSite);
  // useEffect(() => {
  //   if (input == "") {
  //     setSearch("");
  //   }
  // }, [input]);

  return (
    <>
      <div>
        <div className={"title"}>Website Traffic</div>
        <div className={"description"}>
          You can observe and analyze the performance of any inbound traffic on
          the analytics page.
        </div>
        <div className="startRow" style={{ color: "#A5A5A5", margin: "8px 0" }}>
          <i className="meta-crm-icon-ic_info font-size-14 mgr4" />
          Data is displayed based on UTC+0 time zone.
        </div>
      </div>
      <Box
        sx={{ alignItems: "center", bgcolor: "#fff", mt: 2, p: "6px 12px" }}
        className="betweenRow"
      >
        <Box>
          {/* <InputField
            placeholder="Search Traffic Source"
            value={input}
            type="search"
            onBlur={() => setSearch(input)}
            onEnter={() => setSearch(input)}
            onChange={(e) => {
              setInput(e.target.value);
            }}
          /> */}
        </Box>
        <Box className="endRow">
          <MetaCRMDateRange
            custom
            isAllText
            minDate={moment().utc().startOf("day").subtract(89, "day").toDate()}
            defaultRange={isDemoSite ? "custom" : 0}
            startDate={startDate}
            endDate={endDate}
            onChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />

          {isShowObjective && (
            <Button
              variant={VARIANT.OUTLINED}
              size={SIZE.MEDIUM}
              disabled={!attributionConnect}
              onClick={() => {
                navigateEntity("/analytics/website/objective");
              }}
            >
              {formatMessage({
                id: `global.btn.${
                  !_.isEmpty(objectiveData)
                    ? "viewObjective"
                    : "createObjective"
                }`,
              })}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}

export default memo(PerformanceHeader);

import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import _ from "lodash";
import { Box, Grid } from "@mui/material";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { Button, COLOR, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { get } from "lodash";
import ImageUpload from "features/metadesk/components/ImageUpload";
import ImagePreview from "features/metadesk/components/ImagePreview";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/styles";
import {
  StyledContainerTitle,
  StyledInputContainer,
  StyledWhiteCardContainer,
} from "./CampaignCreation.styles";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import { CampaignCreationContext } from "../context/CampaignCreationProvider";
import { apiUrl } from "features/configure";
import axios from "axios";
import {
  useForm,
  Controller,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import EditNotificationPreview from "features/metadesk/campaign/campaignEdit/EditNotificationPreview";
import DateTimePicker from "./DateTimePicker";
import { getMinTime } from "features/helpers/utils";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import {
  getInitialFlyerObject,
  getInitialTrackerObject,
  getInitialWidgetObject,
} from "../utils";
import {
  StyledGoalLabelWrapper,
  StyledPromotypeLabelWrapper,
} from "./SelectCampaignModal.styles";
import IntergrationCover from "./IntergrationCover";
import Schedule from "features/metadesk/campaign/createCampaign/schedule";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";

const WebPush = ({ tabIndex }) => {
  const { notificationId } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const confirm = useConfirm();
  const [entityName, isCustomDomain] = useOutletContext();
  const { formatMessage } = useIntl();
  const ecosystemName = useSelector(selectEcosystemName);
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo },
  } = useFormContext();

  const { append, replace } = useFieldArray({
    control: control,
    name: "campaignContentSets",
  });

  const {
    stepData,
    setStepValues,
    isXmtpConnected,
    isPushConnected,
    isWidgetActive,
    isTrackingActive,
  } = useContext(CampaignCreationContext);
  const { campaignContentSets } = stepData;

  const selectedGoal = _.get(campaignContentSets, `${tabIndex}.goal`);
  const selectedType = _.get(campaignContentSets, `${tabIndex}.type`);

  const goalMenuList = useMemo(() => {
    return [
      {
        label: (
          <StyledGoalLabelWrapper>
            <img
              src={require("assets/img/ic_goal_onchain.svg").default}
              alt=""
            />
            {formatMessage({
              id: "popup.selectCampaignGoal.trackingOnchainBehavior.title",
            })}
            <IconTooltip
              icon={
                <HelpOutlineRoundedIcon
                  sx={{
                    color: theme.customColors.grey[600],
                    fontSize: "14px",
                  }}
                />
              }
              content={formatMessage({
                id: "popup.selectCampaignGoal.trackingOnchainBehavior.tooltip",
              })}
            />
          </StyledGoalLabelWrapper>
        ),
        value: "onchain",
      },
      {
        label: (
          <StyledGoalLabelWrapper>
            <img
              src={require("assets/img/ic_goal_message.svg").default}
              alt=""
            />
            {formatMessage({
              id: "popup.selectCampaignGoal.deliveryOfMessagesOnly.title",
            })}
            <IconTooltip
              icon={
                <HelpOutlineRoundedIcon
                  sx={{
                    color: theme.customColors.grey[600],
                    fontSize: "14px",
                  }}
                />
              }
              content={formatMessage({
                id: "popup.selectCampaignGoal.deliveryOfMessagesOnly.tooltip",
              })}
            />
          </StyledGoalLabelWrapper>
        ),
        value: "message",
      },
    ];
  }, []);

  const promoTypeList = useMemo(() => {
    const contents = [
      {
        label: (
          <StyledPromotypeLabelWrapper>
            <img
              src={require("assets/img/ic_promotype_widget.svg").default}
              height={20}
              width={20}
              alt=""
            />
            {formatMessage({
              id: "popup.selectPromotoType.widget.title",
            })}
            <IconTooltip
              icon={
                <HelpOutlineRoundedIcon
                  sx={{
                    color: theme.customColors.grey[600],
                    fontSize: "14px",
                  }}
                />
              }
              content={formatMessage({
                id: "popup.selectPromotoType.widget.description",
              })}
            />
          </StyledPromotypeLabelWrapper>
        ),
        value: "widget",
      },
    ];
    if (ecosystemName !== "Solana") {
      contents.push({
        label: (
          <StyledPromotypeLabelWrapper>
            <img
              src={require("assets/img/ic_promotype_flyer.svg").default}
              height={20}
              width={20}
              alt=""
            />
            {formatMessage({
              id: "popup.selectPromotoType.flyer.title",
            })}
            <IconTooltip
              icon={
                <HelpOutlineRoundedIcon
                  sx={{
                    color: theme.customColors.grey[600],
                    fontSize: "14px",
                  }}
                />
              }
              content={formatMessage({
                id: "popup.selectPromotoType.flyer.description",
              })}
            />
          </StyledPromotypeLabelWrapper>
        ),
        value: "flyer",
      });
    }
    return contents;
  }, [ecosystemName]);

  const handleFieldChange = ({ event, fieldName }) => {
    const { value } = event.target;
    const temp = [...campaignContentSets];
    temp[tabIndex] = { ...temp[tabIndex], [fieldName]: value };
    setStepValues({ campaignContentSets: temp });
  };

  const handlePromoTypeChange = ({ event }) => {
    const { value } = event.target;
    const temp = [...campaignContentSets];

    if (value === "flyer") {
      temp[tabIndex] = getInitialFlyerObject(selectedGoal);
    }

    if (value === "widget") {
      temp[tabIndex] = getInitialWidgetObject(selectedGoal);
    }

    if (value === "tracker") {
      temp[tabIndex] = getInitialTrackerObject();
    }
    setStepValues({ campaignContentSets: temp });
  };

  const handleGoalChange = ({ event }) => {
    const { value } = event.target;
    const temp = [...campaignContentSets];
    temp[tabIndex] = { ...temp[tabIndex], goal: value };
    temp[tabIndex].engagementDistributionSettings[0].useOnchain =
      value === "onchain";
    setStepValues({ campaignContentSets: temp });
  };

  const handleChangeStartFrom = (value) => {
    setStepValues({ endAt: "" });
    setStepValues({ startFrom: value });
  };

  const handleChangeEndAt = (value) => {
    setStepValues({ endAt: value });
  };

  const setImgs = (value) => {
    handleFieldChange({
      fieldName: "engagementImages",
      event: { target: { value } },
    });
  };

  const handleUploadImg = async (list) => {
    setImgs(list);
    await uploadFile(list[0].file);
  };

  useEffect(() => {
    setImgs(_.get(campaignContentSets[tabIndex], "engagementImages", []));
  }, [_.get(campaignContentSets[tabIndex], "engagementImages", [])]);

  const uploadFile = async (file) => {
    if (file) {
      let imgFormData = new FormData();

      imgFormData.append("campaign", file);
      imgFormData.append("entityName", entityName);
      try {
        const {
          data: { campaign },
        } = await axios.post(`${apiUrl}/api/gcp/uploadCampaign`, imgFormData);
        setImgs([campaign[0]]);
        const temp = [...campaignContentSets];
        temp[tabIndex] = {
          ...temp[tabIndex],
          engagementImages: [campaign[0]],
        };
        setStepValues({ campaignContentSets: temp });
      } catch (error) {
        setImgs([]);
        dispatch(
          enqueueSnackbar({
            message: get(error, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );
      } finally {
      }
    }
  };

  const renderPreviewField = () => {
    return (
      <EditNotificationPreview
        data={campaignContentSets[tabIndex]}
        imgs={campaignContentSets[tabIndex].engagementImages}
        startFrom={stepData.startFrom}
        minHeight={"100%"}
      ></EditNotificationPreview>
    );
  };

  const renderPromoTypeSection = () => {
    return (
      <StyledWhiteCardContainer>
        <StyledContainerTitle>{"Promotype"}</StyledContainerTitle>
        <div className="mhr" style={{ margin: 0 }}></div>
        <StyledInputContainer>
          <div
            className="formField"
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              columnGap: 8,
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: "280px",
              }}
            >
              <p>
                <span style={{ marginRight: "4px" }}>{"Type"}</span>
              </p>
              <CustomDropdown
                border={true}
                data={promoTypeList}
                fullWidth
                size={SIZE.XL}
                placeholder={"Select promo type"}
                value={selectedType}
                onSelect={(e) => {
                  confirm({
                    title: "Are you sure ?",
                    content:
                      "You've selected a new promotion type, so some settings won't be saved at this time.",
                    confirmText: "Change",
                    cancelText: formatMessage({
                      id: "Cancel",
                    }),
                    color: "secondary",
                  })
                    .then(() => {
                      handlePromoTypeChange({
                        event: e,
                      });
                    })
                    .catch((err) => {
                      console.log("cancel", err);
                    });
                }}
              />
            </div>
            <p style={{ position: "relative", top: 10 }}>{"→"}</p>
            <div
              style={{
                width: "100%",
                maxWidth: "280px",
              }}
            >
              <p>
                <span style={{ marginRight: "4px" }}>{"Goal"}</span>
              </p>
              <CustomDropdown
                border={true}
                data={goalMenuList}
                fullWidth
                size={SIZE.XL}
                placeholder={"Select campaign goal"}
                value={selectedGoal}
                onSelect={(e) => {
                  confirm({
                    title: "Are you sure ?",
                    content:
                      "You've selected a new promotion type, so some settings won't be saved at this time.",
                    confirmText: "Change",
                    cancelText: formatMessage({
                      id: "Cancel",
                    }),
                    color: "secondary",
                  })
                    .then(() => {
                      handleGoalChange({
                        event: e,
                      });
                    })
                    .catch((err) => {
                      console.log("cancel", err);
                    });
                }}
              />
            </div>
          </div>
        </StyledInputContainer>
      </StyledWhiteCardContainer>
    );
  };

  const renderCreateField = () => {
    return (
      <StyledWhiteCardContainer>
        <StyledContainerTitle>
          {formatMessage({
            id: "campaignBuild.secondStep.webpush.content.title",
          })}
        </StyledContainerTitle>
        <div className="mhr" style={{ margin: 0 }}></div>
        <StyledInputContainer>
          <div className="formField">
            <InputField
              maxLength={50}
              name={`campaignContentSets[${tabIndex}].engagementTitle`}
              control={control}
              title={
                <div className="formHeader">
                  {formatMessage({ id: "global.column.title" })}{" "}
                  <span className="required">*</span>
                </div>
              }
              errors={get(
                errorsInfo,
                `campaignContentSets.[${[tabIndex]}].engagementTitle.message`
              )}
              rules={{
                required: {
                  value: true,
                  message: `Title ${formatMessage({
                    id: "global.error.required",
                  })}`,
                },
                minLength: {
                  value: 5,
                  message: formatMessage({
                    id: "macrosSetting.macrosCreate.addQuestionMinError",
                  }),
                },
                maxLength: {
                  value: 50,
                  message: formatMessage({
                    id: "macrosSetting.macrosCreate.addQuestionMinError",
                  }),
                },
              }}
              onChange={(event) =>
                handleFieldChange({
                  event,
                  fieldName: "engagementTitle",
                })
              }
              value={_.get(
                campaignContentSets[tabIndex],
                "engagementTitle",
                ""
              )}
              placeholder={formatMessage({ id: "global.column.title" })}
            />
          </div>
          <div className="formField">
            <InputField
              title={
                <div className="formHeader">
                  {formatMessage({ id: "global.column.description" })}{" "}
                  <span className="required">*</span>
                </div>
              }
              name={`campaignContentSets[${tabIndex}].engagementDescription`}
              control={control}
              maxLength={1500}
              errors={get(
                errorsInfo,
                `campaignContentSets.[${[
                  tabIndex,
                ]}].engagementDescription.message`
              )}
              rules={{
                required: {
                  value: true,
                  message: "Description is Required",
                },
              }}
              minRows={8}
              multiline={true}
              InputProps={{
                rows: 8,
                multiline: true,
                inputComponent: "textarea",
              }}
              onChange={(event) =>
                handleFieldChange({
                  event,
                  fieldName: "engagementDescription",
                })
              }
              value={_.get(
                campaignContentSets[tabIndex],
                "engagementDescription",
                ""
              )}
              placeholder={formatMessage({ id: "global.column.description" })}
            />
          </div>
          <div className="formField">
            <div className="formHeader">
              {formatMessage({ id: "uploader.instruction.image" })}
            </div>
            <div
              style={{
                borderRadius: 3,
                border: "1px dashed #E6E6E6",
              }}
            >
              <div style={{ margin: 35 }} className="column">
                {campaignContentSets[tabIndex].engagementImages.length > 0 ? (
                  <ImagePreview
                    imgs={campaignContentSets[tabIndex].engagementImages}
                    setImgs={setImgs}
                    size={200}
                  />
                ) : (
                  <>
                    <i
                      className="meta-crm-icon-ic_uploadImage font-size-72"
                      style={{
                        color: "#E6E6E6",
                        marginBottom: 12,
                      }}
                    />
                    <ImageUpload
                      multiple={false}
                      acceptType={["png", "jpg", "heic", "jpeg"]}
                      uploadComponent={
                        <Button color={COLOR.SECONDARY}>
                          {formatMessage({ id: "global.btn.uploadImage" })}
                        </Button>
                      }
                      value={campaignContentSets[tabIndex].engagementImages}
                      onChange={handleUploadImg}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </StyledInputContainer>
      </StyledWhiteCardContainer>
    );
  };

  const renderScheduleField = () => {
    return (
      <Schedule
        startFrom={stepData.startFrom}
        endAt={stepData.endAt}
        onChangeStartFrom={handleChangeStartFrom}
        onChangeEndAt={handleChangeEndAt}
      ></Schedule>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flex: 1, flexGrow: 1 }}>
        <Box sx={{ position: "relative" }}>
          {/* <Loading open={loading} white={true} fullScreen={false} /> */}
          <Grid container spacing={2} sx={{ marginTop: "8px" }}>
            <Grid item xs={12} md={8}>
              {renderPromoTypeSection()}
              <Box sx={{ position: "relative" }}>
                <IntergrationCover
                  show={
                    (selectedType === "widget" && !isWidgetActive) ||
                    (selectedType === "flyer" &&
                      !(isPushConnected || isXmtpConnected))
                  }
                  type={selectedType}
                />
                <Box mb="20px"></Box>
                {renderCreateField()}
                <Box mb="20px"></Box>
                {renderScheduleField()}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              {renderPreviewField()}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default WebPush;

import React from "react";
import Box from "@mui/material/Box";
import { Button, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import { useTheme } from "@mui/styles";
import { useOutletContext, useNavigate } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";
import AttributionLinkTableContent from "./AttributionLinkTableContent";

import { useIntl } from "react-intl";

const AttributionLink = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const [entityName, isCustomDomain] = useOutletContext();

  const handleToCreateTracker = (targetId, row) => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/analytics/settings/attribution-link/create-campaign`
      )
    );
  };

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} mb="24px">
        <Box>
          <Box className={"title"}>
            {formatMessage({
              id: "analytics.attributionLink.settings.headerTitle",
            })}
          </Box>
          <Box
            sx={{
              fontSize: "14px",
              color: theme.customColors.grey[600],
              marginTop: "12px",
            }}
          >
            {formatMessage({
              id: "analytics.attributionLink.settings.headerDescription",
            })}
          </Box>
        </Box>
        <Box>
          <Button style={{ width: "130px" }} onClick={handleToCreateTracker}>
            Create New
          </Button>
        </Box>
      </Box>
      <Box>
        <AttributionLinkTableContent></AttributionLinkTableContent>
      </Box>
    </>
  );
};

export default AttributionLink;

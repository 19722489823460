import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CRMTable from "@metacrm/metacrm-material-ui/dist/Table";
import { get, map, upperFirst } from "lodash";
import useGetSinglePerformanceTableData from "./hook/useGetPerformanceDetailTable";
import PerformanceModals from "./component/modals";
import { useIntl } from "react-intl";
import CustomPagination from "components/CustomPagination/CustomPagination";
import {
  StatusLabel,
  SIZE,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/StatusLabel";
import moment from "moment";
import UserTag from "features/metadesk/components/UserTag";
import { useOutletContext } from "react-router";
import TableMenu from "components/CustomTable/TableMenu";
import useGetPagePermission from "hooks/useGetPagePermission";
import CountModal from "./component/modals/CountModal";
import UserAddress from "components/UserAddress/UserAddress";

export default function SinglePerformanceTable() {
  const {
    headCells,
    data,
    totalCount,
    page,
    setPage,
    order,
    orderBy,
    handleRequestSort,
    handleUpdateUser,
  } = useGetSinglePerformanceTableData();
  const PAGE_COUNT = 20;
  const { formatMessage } = useIntl();
  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [entityName] = useOutletContext();
  const [modalType, setModalType] = useState("");
  const [countModal, setCountModal] = useState({ isOpen: false, data: [] });

  const renderContents = (data) => {
    const contents = [
      <Box slot="address">
        <UserAddress address={data.address} id={data._id} />
      </Box>,
      <Box
        slot="conversionCount"
        style={{ cursor: "pointer", width: "100%" }}
        onClick={(e) => {
          e.stopPropagation();
          if (data.conversionCount === 0) return;
          setCountModal({
            isOpen: true,
            data: data.conversions,
          });
        }}
      >
        {data.conversionCount > 0 ? (
          <p className="linkCount">{data.conversionCount}</p>
        ) : (
          0
        )}
      </Box>,
      <Box slot="inputCount">{data.inputCount}</Box>,
      <Box slot="userType">
        <StatusLabel
          color={data.userType === "new" ? COLOR.PRIMARY : COLOR.SECONDARY}
        >
          {upperFirst(data.userType)}
        </StatusLabel>
      </Box>,
      <Box slot="tags" style={{ maxWidth: 120 }}>
        <UserTag
          setUser={handleUpdateUser}
          entityName={entityName}
          width={50}
          minWidth={140}
          showNumber={1}
          userInfo={{
            _id: data._id,
            address: data.address,
            tags: get(data, "tags", []),
          }}
        />
      </Box>,
      <Box slot="walletAge">
        {data.walletAge
          ? moment(data.walletAge).fromNow(true)
          : formatMessage({
              id: "walletAnalytics.columnName.walletAge.description",
            })}
      </Box>,
      <Box slot="walletBalance">
        {parseFloat(data.walletBalance.toFixed(2)).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </Box>,
      <Box slot="lastTimestamp">
        {data.lastTimestamp
          ? moment(data.lastTimestamp).format("MMM DD,YYYY HH:mmA")
          : formatMessage({
              id: "walletAnalytics.columnName.userLatestTransactionDate.description",
            })}
      </Box>,
    ];

    return contents;
  };

  const pagePermission = useGetPagePermission();
  const { readonly } = pagePermission("customerList");
  const tabs = readonly
    ? [
        {
          label: `${formatMessage({ id: "global.tableOption.select" })}: ${
            selectedAll ? "All" : selected.length
          }`,
        },
        {
          label: (
            <div className="startRow">
              <i className="meta-crm-icon-ic-download-1 font-size-18 mgr4" />
              {formatMessage({ id: "global.tableOption.export" })}
            </div>
          ),
          onClick: () => {
            setModalType("export");
          },
        },
      ]
    : [
        {
          label: `${formatMessage({ id: "global.tableOption.select" })}: ${
            selectedAll ? "All" : selected.length
          }`,
        },
        {
          label: (
            <div className="startRow">
              <i className="meta-crm-icon-ic_tag font-size-18 mgr4" />
              {formatMessage({ id: "global.tableOption.assignTag" })}
            </div>
          ),
          onClick: () => {
            setModalType("assign");
          },
        },
        {
          label: (
            <div className="startRow">
              <i className="meta-crm-icon-ic_unassignTag font-size-18 mgr4" />
              {formatMessage({ id: "global.tableOption.unassignTag" })}
            </div>
          ),
          onClick: () => {
            setModalType("unassign");
          },
        },
        {
          label: (
            <div className="startRow">
              <i className="meta-crm-icon-ic-download-1 font-size-18 mgr4" />
              {formatMessage({ id: "global.tableOption.export" })}
            </div>
          ),
          onClick: () => {
            setModalType("export");
          },
        },
      ];
  return (
    <Box width={"100%"} overflow={"auto"}>
      <CRMTable
        noKey={true}
        containerStyle={{ overflow: "unset" }}
        rowData={data}
        headers={headCells}
        contents={map(data, (user, i) => renderContents(user))}
        onRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        selectedRowData={selected}
        setSelectedRowData={setSelected}
        selectedAll={selectedAll}
        setSelectedAll={setSelectedAll}
        checkbox={true}
      />
      <CustomPagination
        shape="rounded"
        PAGE_COUNT={PAGE_COUNT}
        count={totalCount}
        page={page}
        setPage={(v) => setPage(v)}
      />
      {selected.length > 0 ? <TableMenu tabs={tabs} /> : null}
      <PerformanceModals
        selected={selected}
        modalType={modalType}
        handleClose={() => setModalType("")}
      />
      <CountModal
        open={countModal.isOpen}
        onClose={() => setCountModal({ isOpen: false, data: [] })}
        data={countModal.data}
      />
    </Box>
  );
}

import React from "react";
import {
  AutoCompleteDropdown,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";

import { useIntl } from "react-intl";

import { Box } from "@mui/material";

export default function AudienceType({
  tabIndex,
  distributionSetting,
  settingIndex,
  segmentOptions,
  aiEnhancedUserOptions,
  selectedSegment,
  selectedTag,
  selectedAiEnhancedUserGrp,
  handleDropdownChange,
  tagOptions,
  customType,
}) {
  const { formatMessage } = useIntl();

  return (
    <>
      {customType === "aiEnhancedUser" && (
        <Box display="flex" alignItems="center">
          <AutoCompleteDropdown
            size={SIZE.XL}
            isOptionPreview={true}
            disableCloseOnSelect={false}
            options={aiEnhancedUserOptions}
            value={selectedAiEnhancedUserGrp(settingIndex)}
            placeholder={formatMessage({
              id: "placeholder.selection.aiEnhancedUser",
            })}
            onChange={(event, newValue) => {
              handleDropdownChange(
                "aiEnhancedUser",
                newValue,
                tabIndex,
                settingIndex,
                distributionSetting.channel
              );
            }}
          />
        </Box>
      )}
      {customType === "segment" && (
        <Box display="flex" alignItems="center">
          <AutoCompleteDropdown
            size={SIZE.XL}
            options={segmentOptions}
            value={selectedSegment(settingIndex)}
            placeholder={formatMessage({ id: "placeholder.selection.segment" })}
            onChange={(event, newValue) => {
              handleDropdownChange(
                "segment",
                newValue,
                tabIndex,
                settingIndex,
                distributionSetting.channel
              );
            }}
          />
        </Box>
      )}
      {customType === "tag" && (
        <Box display="flex" alignItems="center">
          <AutoCompleteDropdown
            size={SIZE.XL}
            options={tagOptions}
            isTag
            value={selectedTag(settingIndex)}
            placeholder={formatMessage({ id: "placeholder.selection.userTag" })}
            onChange={(event, newValue) => {
              handleDropdownChange(
                "tag",
                newValue,
                tabIndex,
                settingIndex,
                distributionSetting.channel
              );
            }}
          />
        </Box>
      )}
    </>
  );
}

import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import { CampaignCreationContext } from "./context/CampaignCreationProvider";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useTheme } from "@mui/styles";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";

import {
  StyledContainerTitle,
  StyledWhiteCardContainer,
} from "./component/CampaignCreation.styles";

import { get, isEmpty, find, filter, isArray } from "lodash-es";
import axios from "axios";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";

import { v4 as uuidv4 } from "uuid";
import Objective from "./component/Objective";
import { AddButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { useIntl } from "react-intl";
import CustomStepper from "components/CustomStepper/CustomStepper";
import Schedule from "./schedule";

export default function CampaignStep3({ step, stepName }) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [entityName, isCustomDomain] = useOutletContext();
  const { stepData, setStepValues, handleSetIsValid, isTracker } = useContext(
    CampaignCreationContext
  );
  const { objectives } = stepData;
  const theme = useTheme();
  const [latestObjectives, setLatestObjectives] = useState([]);
  const [isLatestObjectiveLoading, setIsLatestObjectiveLoading] =
    useState(false);

  const methods = useForm({
    defaultValues: {
      objectiveList: [],
      name: "",
      description: "",
    },
    mode: "onChange",
  });
  const { append, replace, remove } = useFieldArray({
    control: methods.control,
    name: "objectiveList",
  });

  const {
    formState: { errors: errorsInfo, isValid },
  } = methods;

  useEffect(() => {
    if (!isEmpty(stepData)) {
      methods.setValue("name", stepData.name); // 設置 name 字段
      methods.setValue("description", stepData.description); // 設置 description 字段
    }
  }, [stepData]);

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setStepValues({ [name]: value });
  };

  useEffect(() => {
    if (isEmpty(objectives)) {
      setStepValues({
        objectives: [
          {
            type: "",
            chainId: "",
            smartContractAddress: "",
            smartContractABI: "",
            method: null,
            _id: uuidv4(),
          },
        ],
      });
    } else {
      const stepValuesWithoutId = objectives.map((item) => {
        return {
          type: item.type,
          chainId: item.chainId,
          smartContractAddress: item.smartContractAddress,
          smartContractABI: item.smartContractABI,
          method: item.method,
        };
      });
      replace(stepValuesWithoutId);
    }
  }, []);

  useEffect(() => {
    const isNextValid = isValid;

    handleSetIsValid(isNextValid);
  }, [isValid, objectives]);

  const handleAddObjective = () => {
    setStepValues({
      objectives: [
        ...objectives,
        {
          type: "",
          chainId: "",
          smartContractAddress: "",
          smartContractABI: "",
          method: null,
          _id: uuidv4(),
        },
      ],
    });
  };

  const handleDeleteObjective = (targetId, objectiveIndex) => {
    const newArr = objectives.filter((item) => item._id !== targetId);
    setStepValues({ objectives: newArr });
    remove(objectiveIndex);
  };

  const handleGetLatestObjective = async () => {
    setIsLatestObjectiveLoading(true);
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/campaign/${entityName}/objective/latest`
      );
      setLatestObjectives(data);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLatestObjectiveLoading(false);
    }
  };

  useEffect(() => {
    handleGetLatestObjective();
  }, []);

  const handleChangeStartFrom = (value) => {
    setStepValues({ endAt: "" });
    setStepValues({ startFrom: value });
  };

  const handleChangeEndAt = (value) => {
    setStepValues({ endAt: value });
  };

  return (
    <>
      <FormProvider {...methods}>
        <Box>
          {isTracker ? (
            <>
              <Box className="title" mb={1}>
                {formatMessage({
                  id: "analytics.attributionLink.settingsSecondStep.headerTitle",
                })}
              </Box>

              <Box className="description" mb={"22px"}>
                {formatMessage({
                  id: "analytics.attributionLink.settingsSecondStep.headerDescription",
                })}
              </Box>
            </>
          ) : (
            <>
              <Box className="title" mb={1}>
                {formatMessage(
                  {
                    id: "campaignBuild.thirdStep.title",
                  },
                  {
                    step: <span style={{ color: "#7B61FF" }}> {step}/3</span>,
                  }
                )}
              </Box>
              <Box className="description" mb={"22px"}>
                {formatMessage({ id: "campaignBuild.thirdStep.description" })}
              </Box>
            </>
          )}
        </Box>

        {isTracker && (
          <>
            <StyledWhiteCardContainer
              display={"flex"}
              justifyContent={"center"}
              sx={{
                paddingBottom: "20px",
                paddingTop: "42px",
                marginBottom: "20px",
              }}
            >
              <Box width="50%">
                <CustomStepper
                  steps={stepName}
                  activeStep={step - 1}
                ></CustomStepper>
              </Box>
            </StyledWhiteCardContainer>
            <StyledWhiteContainer sx={{ marginBottom: "20px" }}>
              {/* name input */}
              <Box sx={{ marginBottom: "20px" }}>
                <InputField
                  title={formatMessage({
                    id: "campaignBuild.info.campaignName",
                  })}
                  isRequired={true}
                  placeholder={formatMessage({
                    id: "campaignBuild.firstStep.campaignName.placeholder",
                  })}
                  name="name"
                  value={stepData.name}
                  onChange={handleFieldChange}
                  errors={get(errorsInfo, "name.message")}
                  control={methods.control}
                  maxLength={50}
                  rules={{
                    required: {
                      value: true,
                      message:
                        formatMessage({ id: "global.commonWord.name" }) +
                        formatMessage({ id: "global.error.required" }),
                    },
                  }}
                />
              </Box>
              {/* description input */}
              <Box>
                <InputField
                  title={formatMessage({
                    id: "campaigns.header.campaignDescription",
                  })}
                  multiline={true}
                  rows={8}
                  maxLength={1000}
                  placeholder={formatMessage({
                    id: "global.column.description",
                  })}
                  name="description"
                  value={stepData.description}
                  onChange={handleFieldChange}
                />
              </Box>
            </StyledWhiteContainer>

            <Schedule
              startFrom={stepData.startFrom}
              endAt={stepData.endAt}
              onChangeStartFrom={handleChangeStartFrom}
              onChangeEndAt={handleChangeEndAt}
            ></Schedule>
          </>
        )}

        <StyledWhiteCardContainer>
          <StyledContainerTitle>
            {formatMessage({ id: "campaignBuild.thirdStep.subtitle" })}
          </StyledContainerTitle>
          <div className="mhr" style={{ margin: 0 }}></div>
          <StyledWhiteContainer>
            {objectives.map((objectiveItem, index) => (
              <Objective
                objectiveIndex={index}
                key={objectiveItem._id}
                onDelete={handleDeleteObjective}
                latestObjectives={latestObjectives}
                formData={stepData}
                setFormData={setStepValues}
              ></Objective>
            ))}

            <Box
              sx={{
                border: `1px dashed ${theme.customColors.grey[400]}`,
                mb: 3,
                mt: "14px",
              }}
            ></Box>
            <AddButton color="#E6E6E6" onClick={handleAddObjective}>
              {formatMessage({
                id: "campaignBuild.thirdStep.addObjective.btn",
              })}
            </AddButton>
          </StyledWhiteContainer>
        </StyledWhiteCardContainer>
      </FormProvider>
    </>
  );
}

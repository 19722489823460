import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import _ from "lodash";
import { useFetchTicketList } from "./redux/hooks";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { makeStyles, useTheme } from "@mui/styles";
import UserInfoRoot from "./UserInfoRoot";

import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import {
  Button,
  IconButton,
  COLOR,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { enqueueSnackbar } from "features/common/redux/actions";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useDispatch, useSelector } from "react-redux";
import { parseCustomDomainUrl } from "features/helpers/utils";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { Loading } from "features/common";
import { useFetchUserInfo } from "./redux/fetchUserInfo";
import GroupLabel from "./group/GroupLabel";
import UserTag from "./components/UserTag";
import Back from "./components/Back";
import { formatDiscordUsername } from "features/helpers/utils";
import Box from "@mui/material/Box";
import useGetPagePermission from "hooks/useGetPagePermission";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import LoadingIcon from "components/LoadingComponent/LoadingIcon";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "24px",
    color: "#383538",
  },
  input: {
    background: "#FFFFFF",
    padding: "10px 10px",
    height: 40,
    margin: "5px 0",
    position: "relative",
    border: "2px solid #7B61FF",
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
    borderRadius: 5,
    fontWeight: 700,
    fontSize: 14,
    alignItems: "center",
  },
  inboxDescriptionLabel: {
    textAlign: "left",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "24px",
    color: "#383538",
  },
  accountInfo: {
    border: "1px solid #d6d6d6",
    borderRadius: 4,
    padding: "7px 14px",
    overflow: "hidden",
    fontWeight: 500,
    fontSize: 14,
    position: "relative",
  },
}));
const SingleUserTicketList = () => {
  const classes = useStyles();
  const [entityName, isCustomDomain] = useOutletContext();
  const { userId } = useParams();

  const { auth } = useConnectWallet();
  const [discordIdInput, setDiscordIdInput] = useState("");
  const [discordUserResult, setDiscordUserResult] = useState("");
  const dispatch = useDispatch();
  const { fetchUserTicketList, userTicketList } = useFetchTicketList();
  const { fetchUserInfo, userInfos, fetchUserInfoPending } = useFetchUserInfo();
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const ecosystemName = useSelector(selectEcosystemName);

  useEffect(() => {
    if (auth && userId && entityName) {
      fetchUserInfo({ entityName, userId });
      fetchUserTicketList({ entityName, userId });
    }
  }, [entityName, userId, auth]);

  const ticketListData = _.get(userTicketList, `[${userId}]`, {});
  const userInfoData = _.get(userInfos, `[${userId}]`, {});
  const userAddress = _.get(userInfoData, "addresses.0.address");
  const userTags = _.get(userInfoData, "tags", []);
  const groups = _.get(ticketListData, `groups`, []);

  const renderCopy = (content, isAbsolute = false) => {
    return (
      <IconButton
        style={{
          position: isAbsolute ? "absolute" : "relative",
          right: "0px",
          padding: "0 5px",
          background: "white",
        }}
        onClick={() => {
          navigator.clipboard.writeText(content);
        }}
      >
        <img src={require("assets/img/copy.svg").default} />
      </IconButton>
    );
  };

  const saveDiscordId = async () => {
    try {
      await axios.post(apiUrl + "/api/user/updateDiscord", {
        userId,
        entityName,
        handle: discordUserResult.handle,
        uid: discordUserResult.uid,
      });
      fetchUserTicketList({ entityName, userId });
      fetchUserInfo({ entityName, userId });
      setDiscordIdInput("");
      setDiscordUserResult("");
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: _.get(error, "response.data.error", "Error"),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        })
      );
    }
  };

  const onSearchDiscordUser = async () => {
    if (!discordIdInput) return;
    try {
      setDiscordUserResult({
        loading: true,
      });
      const response = await axios.post(
        `https://lookupguru.herokuapp.com/lookup`,
        {
          input: discordIdInput,
        }
      );

      const result = response.data;
      if (result.success)
        setDiscordUserResult({
          uid: discordIdInput,
          handle: result.data.username + "#" + result.data.discriminator,
        });
      else {
        throw result.error;
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "User not found",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        })
      );
      setDiscordUserResult("");
    }
  };

  const renderDiscordInput = () => {
    const data = _.get(userInfoData, "mostRecentDiscordID.handle", "");
    const discordUsername = formatDiscordUsername(data);
    return (
      <div style={{ marginBottom: 15, position: "relative" }}>
        <div className={classes.label}>Discord ID</div>
        {discordUserResult && (
          <div
            style={{
              position: "absolute",
              top: 55,
              zIndex: 1500,
              left: 0,
              background: "#FFFFFF",
              width: 260,
              borderRadius: 4,
              padding: "3px 0",
              border: "1px solid #D6D6D6",
            }}
          >
            <div
              style={{
                background: "#F7F1FE",
                height: 40,
                padding: "4px 7px",
                position: "relative",
              }}
            >
              {discordUserResult.loading ? (
                <div
                  style={{
                    color: "#7B61FF",
                    fontSize: 13,
                    fontWeight: 500,
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  Searching...{" "}
                  <LoadingIcon className="xsIcon" style={{ marginLeft: 10 }} />
                </div>
              ) : (
                <div className="betweenRow">
                  <span style={{ fontWeight: 500, fontSize: 14 }}>
                    {discordUserResult.handle}
                  </span>
                  <Button color="primary" size="sm" onClick={saveDiscordId}>
                    Select
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        {!discordUsername ? (
          <div
            style={{
              position: "relative",
            }}
          >
            <InputField
              onChange={(e) => setDiscordIdInput(e.target.value)}
              value={discordIdInput}
              placeholder={"Give an ID here..."}
              endAdornment={
                discordIdInput && (
                  <Button
                    color={COLOR.SECONDARY}
                    size={SIZE.XS}
                    onClick={onSearchDiscordUser}
                  >
                    Search
                  </Button>
                )
              }
            />
          </div>
        ) : (
          <div className={classes.accountInfo}>
            <div className="betweenRow">
              <span
                style={{
                  display: "inline-block",
                  overflow: "hidden",
                  maxWidth: 160,
                }}
              >
                {discordUsername || "-"}
              </span>
              <div>{renderCopy(discordUsername)}</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const pagePermission = useGetPagePermission();
  const { show } = pagePermission("tickets");

  const renderView = () => {
    if (!auth) {
      return <Loading open={true} fullScreen={true} />;
    }
    return (
      <Box className="container-inside" sx={{ padding: "0 20px" }}>
        <Back text={"Customers"} />
        <div className={computer ? "startRow" : ""}>
          <div
            style={{
              width: computer ? 310 : "100%",
              minWidth: 320,
            }}
          >
            <div
              className="card"
              style={{
                height: "calc(100vh - 121px)",
                overflow: "auto",
                marginTop: 0,
              }}
            >
              <div style={{ padding: 20 }}>
                {renderDiscordInput()}{" "}
                <div className={classes.inboxDescriptionLabel}>Tag</div>
                <div className={"inputField"}>
                  <UserTag
                    width={"100%"}
                    showNumber={10}
                    entityName={entityName}
                    userInfo={{
                      _id: userId,
                      address: userAddress,
                      tags: userTags,
                    }}
                  />
                </div>
                {ecosystemName === "EVM" && (
                  <>
                    <div
                      className={classes.inboxDescriptionLabel}
                      style={{ marginTop: 12 }}
                    >
                      Segment
                    </div>
                    <div className={"inputField"}>
                      <GroupLabel
                        entityName={entityName}
                        groups={groups}
                        all={true}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              width: computer ? "calc(100% - 320px)" : "100%",
            }}
          >
            <div
              className="card"
              style={{
                padding: 0,
                marginTop: 0,
                height: "calc(100vh - 121px)",
                overflow: "auto",
                background: "#F8F8F8",
                position: "relative",
              }}
            >
              <Loading open={fetchUserInfoPending} fullScreen={false} white />
              <UserInfoRoot
                selectedUserId={userId}
                selectedUserAddress={userAddress}
                entityName={entityName}
                ticketListData={show ? ticketListData : null}
              />
            </div>
          </div>
        </div>
      </Box>
    );
  };
  return (
    <div
      style={{
        background: "#F8F8F8",
      }}
    >
      {renderView()}
    </div>
  );
};

export default SingleUserTicketList;

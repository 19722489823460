import React, { useContext, useMemo, useState } from "react";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import PureModal from "../../campaignList/component/PureModal";
import { CampaignCreationContext } from "../context/CampaignCreationProvider";
import {
  StyledSelectCampaignCard,
  StyledSelectCampaignCardTitle,
  StyledSelectCampaignColumnHr,
  StyledSelectCampaignSubtitle,
  StyledSelectCampaignTitle,
  StyledSelectCampaignCardCover,
  StyledGoalLabelWrapper,
} from "./SelectCampaignModal.styles";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  getInitialFlyerObject,
  getInitialTrackerObject,
  getInitialWidgetObject,
} from "../utils/index";
import _ from "lodash";
import { useIntl } from "react-intl";
import { useOutletContext, useNavigate } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Button, {
  COLOR,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import IntergrationCover from "./IntergrationCover";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";

const PROMOTION_TYPE = {
  flyer: "flyer",
  widget: "widget",
  tracker: "tracker",
};

export default function SelectCampaignModal({ setStep, open, onClose, step }) {
  const {
    stepData,
    setStepValues,
    isWidgetActive,
    isPushConnected,
    isXmtpConnected,
    isTrackingActive,
  } = useContext(CampaignCreationContext);
  const { campaignContentSets } = stepData;
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const [promotionType, setPromotionType] = useState("");
  const [widgetGoal, setWidgetGoal] = useState("");
  const [flyerGoal, setFlyerGoal] = useState("");
  const ecosystemName = useSelector(selectEcosystemName);

  const isValid =
    promotionType === PROMOTION_TYPE.tracker ||
    (promotionType === PROMOTION_TYPE.widget && widgetGoal) ||
    (promotionType === PROMOTION_TYPE.flyer && flyerGoal);

  const goalMenuList = useMemo(() => {
    return [
      {
        label: (
          <StyledGoalLabelWrapper>
            <img
              src={require("assets/img/ic_goal_onchain.svg").default}
              alt=""
            />
            {formatMessage({
              id: "popup.selectCampaignGoal.trackingOnchainBehavior.title",
            })}

            <IconTooltip
              icon={
                <HelpOutlineRoundedIcon
                  sx={{
                    color: theme.customColors.grey[600],
                    fontSize: "14px",
                  }}
                />
              }
              content={formatMessage({
                id: "popup.selectCampaignGoal.trackingOnchainBehavior.tooltip",
              })}
            />
          </StyledGoalLabelWrapper>
        ),
        value: "onchain",
      },
      {
        label: (
          <StyledGoalLabelWrapper>
            <img
              src={require("assets/img/ic_goal_message.svg").default}
              alt=""
            />
            {formatMessage({
              id: "popup.selectCampaignGoal.deliveryOfMessagesOnly.title",
            })}
            <IconTooltip
              icon={
                <HelpOutlineRoundedIcon
                  sx={{
                    color: theme.customColors.grey[600],
                    fontSize: "14px",
                  }}
                />
              }
              content={formatMessage({
                id: "popup.selectCampaignGoal.deliveryOfMessagesOnly.tooltip",
              })}
            />
          </StyledGoalLabelWrapper>
        ),
        value: "message",
      },
    ];
  }, []);

  function handleSubmit() {
    if (step === 1 && campaignContentSets.length > 0) {
    } else if (promotionType === PROMOTION_TYPE.flyer) {
      setStepValues({
        campaignContentSets: [
          ...campaignContentSets,
          getInitialFlyerObject(flyerGoal),
        ],
      });
    } else if (promotionType === PROMOTION_TYPE.widget) {
      setStepValues({
        campaignContentSets: [
          ...campaignContentSets,
          getInitialWidgetObject(widgetGoal),
        ],
      });
    } else {
      setStepValues({
        campaignContentSets: [
          ...campaignContentSets,
          getInitialTrackerObject(),
        ],
      });
    }
    setStep(2);
    onClose();
  }

  function handleGoalSettingChange(promotionType, value = "") {
    switch (promotionType) {
      case PROMOTION_TYPE.flyer: {
        setFlyerGoal(value);
        setPromotionType(PROMOTION_TYPE.flyer);
        setWidgetGoal("");
        break;
      }

      case PROMOTION_TYPE.widget: {
        setWidgetGoal(value);
        setPromotionType(PROMOTION_TYPE.widget);
        setFlyerGoal("");
        break;
      }

      case PROMOTION_TYPE.tracker: {
        setWidgetGoal("");
        setFlyerGoal("");
        setPromotionType(PROMOTION_TYPE.tracker);
        break;
      }

      default: {
      }
    }
  }

  function isActivePromotionType(currSelectedType, promotionType) {
    return currSelectedType === promotionType;
  }

  return (
    <PureModal
      open={open}
      onClose={onClose}
      padding={"60px 30px"}
      maxWidth="1100px"
      overflow="auto"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <StyledSelectCampaignTitle className="StyledSelectCampaignTitle">
          {formatMessage({ id: "popup.selectPromotoType.title" })}
        </StyledSelectCampaignTitle>
        <StyledSelectCampaignSubtitle>
          {formatMessage({ id: "popup.selectPromotoType.description" })}
        </StyledSelectCampaignSubtitle>
        <Box display={"flex"} flexDirection={"column"} rowGap={"14px"}>
          {/* Widget campaign card*/}
          <StyledSelectCampaignCard
            isActive={isActivePromotionType(
              promotionType,
              PROMOTION_TYPE.widget
            )}
          >
            <IntergrationCover type={"widget"} show={!isWidgetActive} />
            <img
              src={require("assets/img/ic_promotype_widget.svg").default}
              alt=""
            />
            <div>
              <StyledSelectCampaignCardTitle>
                {formatMessage({
                  id: "popup.selectPromotoType.widget.title",
                })}
              </StyledSelectCampaignCardTitle>

              <StyledSelectCampaignSubtitle
                style={{ marginBottom: 16, fontSize: 14, maxWidth: 650 }}
              >
                {formatMessage({
                  id: "popup.selectPromotoType.widget.description",
                })}
              </StyledSelectCampaignSubtitle>
            </div>
            <div
              style={{
                width: "100%",
                maxWidth: "280px",
                margin: "auto",
              }}
            >
              <CustomDropdown
                style={{
                  ...(isActivePromotionType(
                    promotionType,
                    PROMOTION_TYPE.widget
                  ) && {
                    border: `1px solid ${theme.customColors.purple[500]}`,
                    borderRadius: "4px",
                  }),
                }}
                border={true}
                data={goalMenuList}
                fullWidth
                size={SIZE.XL}
                placeholder={"Select campaign goal"}
                value={widgetGoal}
                onSelect={(e) => {
                  const { value } = e.target;
                  handleGoalSettingChange(PROMOTION_TYPE.widget, value);
                }}
              />
            </div>
          </StyledSelectCampaignCard>

          {/* Engagement Campaign Card */}
          {ecosystemName !== "Solana" && (
            <StyledSelectCampaignCard
              isActive={isActivePromotionType(
                promotionType,
                PROMOTION_TYPE.flyer
              )}
            >
              <IntergrationCover
                type={"flyer"}
                show={!isPushConnected && !isXmtpConnected}
              />

              <img
                src={require("assets/img/ic_promotype_flyer.svg").default}
                alt=""
              />
              <div>
                <StyledSelectCampaignCardTitle>
                  {formatMessage({
                    id: "popup.selectPromotoType.flyer.title",
                  })}
                </StyledSelectCampaignCardTitle>

                <StyledSelectCampaignSubtitle
                  style={{ marginBottom: 16, fontSize: 14, maxWidth: 650 }}
                >
                  {formatMessage({
                    id: "popup.selectPromotoType.flyer.description",
                  })}
                </StyledSelectCampaignSubtitle>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "12px",
                  }}
                >
                  <StyledSelectCampaignSubtitle
                    style={{ fontSize: "12px", margin: 0 }}
                  >
                    {formatMessage({
                      id: "popup.selectPromotoType.flyer.channelInfo",
                    })}
                  </StyledSelectCampaignSubtitle>
                  <Box
                    display="flex"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      {formatMessage({ id: "global.appname.xmtp" })}
                    </Box>
                    <StyledSelectCampaignColumnHr />
                    <Box display="flex" alignItems="center">
                      {formatMessage({ id: "global.appname.push" })}
                    </Box>
                  </Box>
                </Box>
              </div>
              <div
                style={{
                  width: "100%",
                  maxWidth: "280px",
                  margin: "auto",
                }}
              >
                <CustomDropdown
                  style={{
                    ...(isActivePromotionType(
                      promotionType,
                      PROMOTION_TYPE.flyer
                    ) && {
                      border: `1px solid ${theme.customColors.purple[500]}`,
                      borderRadius: "4px",
                    }),
                  }}
                  border
                  data={goalMenuList}
                  fullWidth
                  size={SIZE.XL}
                  placeholder={"Select campaign goal"}
                  value={flyerGoal}
                  onSelect={(e) => {
                    const { value } = e.target;
                    handleGoalSettingChange(PROMOTION_TYPE.flyer, value);
                  }}
                />
              </div>
            </StyledSelectCampaignCard>
          )}

          {/* Custom Campaign Card */}
          {/* <StyledSelectCampaignCard
            onClick={() =>
              isTrackingActive
                ? handleGoalSettingChange(PROMOTION_TYPE.tracker)
                : () => {}
            }
            isActive={isActivePromotionType(
              promotionType,
              PROMOTION_TYPE.tracker
            )}
          >
            <IntergrationCover type={"tracker"} show={!isTrackingActive} />
            <img
              src={require("assets/img/ic_promotype_tracker.svg").default}
              alt=""
            />
            <div>
              <StyledSelectCampaignCardTitle>
                {formatMessage({
                  id: "popup.selectPromotoType.tracker.title",
                })}
              </StyledSelectCampaignCardTitle>

              <StyledSelectCampaignSubtitle
                style={{ marginBottom: 16, fontSize: 14, maxWidth: 650 }}
              >
                {formatMessage({
                  id: "popup.selectPromotoType.tracker.description",
                })}
              </StyledSelectCampaignSubtitle>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "12px",
                }}
              >
                <StyledSelectCampaignSubtitle
                  style={{ fontSize: "12px", margin: 0 }}
                >
                  {formatMessage({
                    id: "popup.selectPromotoType.tracker.channelInfo",
                  })}
                </StyledSelectCampaignSubtitle>
                <Box
                  sx={{ fontSize: "12px", fontWeight: 700, marginTop: "2.5px" }}
                  display="flex"
                  alignItems="center"
                >
                  {imgs.map((item, index) => (
                    <img
                      key={index}
                      style={{ marginLeft: "8px" }}
                      width="20px"
                      height="20px"
                      src={require(`assets/img/source/${item}.svg`)}
                    />
                  ))}
                </Box>
              </Box>
            </div>
          </StyledSelectCampaignCard> */}
        </Box>
        <Box
          display={"flex"}
          columnGap={"8px"}
          justifyContent={"flex-end"}
          marginTop={"16px"}
        >
          <Button
            color={COLOR.SECONDARY}
            variant={VARIANT.OUTLINED}
            onClick={onClose}
            style={{ width: 120 }}
          >
            {formatMessage({ id: "global.btn.cancel" })}
          </Button>
          <Button
            color={COLOR.SECONDARY}
            disabled={!isValid}
            style={{ width: 120 }}
            onClick={handleSubmit}
          >
            {"Submit"}
          </Button>
        </Box>
      </Box>
    </PureModal>
  );
}

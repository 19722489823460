import React, { useEffect, useMemo, useState } from "react";
import PerformanceHeader from "./PerformanceHeader";
import { PerformanceProvider } from "./context/PerformanceProvider";
import useGetPerformanceChart from "./hook/useGetPerformanceChart";
import ReferrerTable from "./ReferrerTable";
import LineChart from "./LineChart";
import { Box, Grid } from "@mui/material";
import DonutChart from "./component/DonutChart";
import BarChart from "./component/BarChart";
import MapChart from "./component/MapChart";
import { useSelector } from "react-redux";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import axios from "axios";
import { useOutletContext } from "react-router";
import { apiUrl } from "features/configure";
import useMarketModule from "hooks/useMarketModule";
import useNavigateEntity from "hooks/useNavigateEntity";
import HoverImage from "../settings/component/hoverImage";
import { useIntl } from "react-intl";

export default function CampaignPerformance() {
  return (
    <PerformanceProvider>
      <Charts />
    </PerformanceProvider>
  );
}

const Charts = () => {
  const { os, device, browser, referrers, performance, country } =
    useGetPerformanceChart();
  const [entityName] = useOutletContext();
  const [objectiveData, setObjectiveData] = useState();
  const { navigateEntity } = useNavigateEntity();
  const { formatMessage } = useIntl();
  const ecosystemName = useSelector(selectEcosystemName);
  const isShowObjective = ecosystemName !== "Aptos";
  const { attributionConnect } = useMarketModule();

  useEffect(async () => {
    try {
      if (!isShowObjective) return;
      const result = await axios.get(
        apiUrl + `/api/trackingObjective/${entityName}`
      );
      setObjectiveData(result.data);
    } catch (err) {}
  }, [isShowObjective]);

  const mapping = useMemo(() => {
    const createReducer = (keyField) => (acc, cur) => ({
      ...acc,
      [cur[keyField]]: cur,
      totalUsers: acc.totalUsers + cur.totalUser,
      totalWallets: acc.totalWallets + cur.walletsConnected,
      ...(isShowObjective && {
        objectiveAchieved: acc.objectiveAchieved + (cur.objectiveAchieved || 0),
      }),
    });

    const initialValue = {
      totalUsers: 0,
      totalWallets: 0,
      ...(isShowObjective && { objectiveAchieved: 0 }),
    };

    return {
      os: os?.reduce(createReducer("os"), initialValue),
      device: device?.reduce(createReducer("device"), initialValue),
      browser: browser?.reduce(createReducer("browser"), initialValue),
      total: performance?.reduce(
        (acc, cur) => ({
          users: acc.users + cur.totalUser,
          wallets: acc.wallets + cur.walletsConnected,
          ...(isShowObjective && {
            objectiveAchieved:
              acc.objectiveAchieved + (cur.objectiveAchieved || 0),
          }),
        }),
        {
          users: 0,
          wallets: 0,
          ...(isShowObjective && { objectiveAchieved: 0 }),
        }
      ),
    };
  }, [os, device, browser, performance, isShowObjective]);

  return (
    <>
      <PerformanceHeader
        isShowObjective={isShowObjective}
        objectiveData={objectiveData}
      />
      {attributionConnect ? (
        <ReferrerTable
          referrers={referrers}
          total={mapping.total}
          objectiveData={objectiveData}
          isShowObjective={isShowObjective}
        />
      ) : (
        <Box mt={"10px"}>
          <HoverImage
            originalImg={require("assets/img/WebsiteTraffic_down.jpg")}
            hoverShowImg={require("assets/img/connect_discord.svg").default}
            hoverShowText={formatMessage({
              id: "analytics.websiteTraffic.performance.disableState",
            })}
            onClick={() => {
              navigateEntity(
                "/settings/general/integration/attribution?from=analytics/website"
              );
            }}
          ></HoverImage>
        </Box>
      )}

      <Grid spacing={1} container sx={{ mt: "24px" }} alignItems="stretch">
        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
          {attributionConnect ? (
            <LineChart
              performance={performance}
              isShowObjective={isShowObjective}
            />
          ) : (
            <HoverImage
              originalImg={require("assets/img/WebsiteTraffic_down1.jpg")}
              hoverShowImg={require("assets/img/connect_discord.svg").default}
              hoverShowText={formatMessage({
                id: "analytics.websiteTraffic.performance.disableState",
              })}
              originalImgHeight="300px"
              onClick={() => {
                navigateEntity(
                  "/settings/general/integration/attribution?from=analytics/website"
                );
              }}
            ></HoverImage>
          )}
        </Grid>

        <Grid item xs={12} md={3} sx={{ height: "100%" }}>
          {attributionConnect ? (
            <DonutChart
              title="Active User by Operating System"
              data={os.map((o) => ({ name: o.os, value: o.totalUser }))}
              tooltip={function (params) {
                const { name, value } = params;
                return `${name}<br/>Users ${
                  mapping.os[name].totalUser
                }<br/>Wallets ${
                  mapping.os[name].walletsConnected
                }<br/>Objective Achieved ${
                  mapping.os[name].objectiveAchieved || "-"
                }`;
              }}
            />
          ) : (
            <HoverImage
              originalImg={require("assets/img/WebsiteTraffic_down2.jpg")}
              hoverShowImg={require("assets/img/connect_discord.svg").default}
              originalImgHeight="300px"
              hoverShowText={formatMessage({
                id: "analytics.websiteTraffic.performance.disableState",
              })}
              onClick={() => {
                navigateEntity(
                  "/settings/general/integration/attribution?from=analytics/website"
                );
              }}
            ></HoverImage>
          )}
        </Grid>

        <Grid item xs={12} md={3} sx={{ height: "100%" }}>
          {attributionConnect ? (
            <DonutChart
              title="Active User by Device"
              data={device.map((d) => ({
                name: d.device,
                value: d.totalUser,
              }))}
              tooltip={function (params) {
                const { name, value } = params;
                return `${name}<br/>Users ${
                  mapping.device[name].totalUser
                }<br/>Wallets ${
                  mapping.device[name].walletsConnected
                }<br/>Objective Achieved ${
                  mapping.device[name].objectiveAchieved || "-"
                }`;
              }}
            />
          ) : (
            <HoverImage
              originalImg={require("assets/img/WebsiteTraffic_down3.jpg")}
              hoverShowImg={require("assets/img/connect_discord.svg").default}
              originalImgHeight="300px"
              hoverShowText={formatMessage({
                id: "analytics.websiteTraffic.performance.disableState",
              })}
              onClick={() => {
                navigateEntity(
                  "/settings/general/integration/attribution?from=analytics/website"
                );
              }}
            ></HoverImage>
          )}
        </Grid>
        <Grid item xs={12} md={9} sx={{ height: "100%" }}>
          {attributionConnect ? (
            <MapChart country={country} isShowObjective={isShowObjective} />
          ) : (
            <HoverImage
              originalImg={require("assets/img/WebsiteTraffic_down4.jpg")}
              hoverShowImg={require("assets/img/connect_discord.svg").default}
              hoverShowText={formatMessage({
                id: "analytics.websiteTraffic.performance.disableState",
              })}
              onClick={() => {
                navigateEntity(
                  "/settings/general/integration/attribution?from=analytics/website"
                );
              }}
            ></HoverImage>
          )}
        </Grid>
        <Grid item xs={12} md={3} sx={{ height: "100%" }}>
          {attributionConnect ? (
            <BarChart
              isShowObjective={isShowObjective}
              title="Active User by Browser"
              data={browser}
            />
          ) : (
            <HoverImage
              originalImg={require("assets/img/WebsiteTraffic_down5.jpg")}
              hoverShowImg={require("assets/img/connect_discord.svg").default}
              hoverShowText={formatMessage({
                id: "analytics.websiteTraffic.performance.disableState",
              })}
              onClick={() => {
                navigateEntity(
                  "/settings/general/integration/attribution?from=analytics/website"
                );
              }}
            ></HoverImage>
          )}
        </Grid>
      </Grid>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import _ from "lodash";
import { Grid, useMediaQuery } from "@mui/material";
import { useFetchGroups } from "../redux/hooks";

import { Button } from "@metacrm/metacrm-material-ui/dist/Button";
import GroupCardNew from "./CreateGroup/GroupCardNew/GroupCardNew";
import Box from "@mui/material/Box";

import { useNavigate, useOutletContext } from "react-router-dom";
import CreateGroupModal from "./CreateGroupModal";
import { parseCustomDomainUrl } from "features/helpers/utils";

import useGetPagePermission from "hooks/useGetPagePermission";
import LoadingIcon from "components/LoadingComponent/LoadingIcon";
const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 700,
    fontSize: 20,
  },
  groupHeader: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "22px",

    color: "#383538",
  },
  rules: { color: "#7B61FF", fontWeight: 500, fontSize: 12 },
  ruleTitle: { fontWeight: 500, fontSize: 13, marginTop: 5 },
  ruleValue: { fontWeight: 500, fontSize: 16 },
}));

const GroupList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [entityName, isCustomDomain] = useOutletContext();
  const {
    groups,
    groupsCount,
    fetchGroupsPending,
    fetchGroupsDone,
    removeGroup,
    editGroup,
    fetchGroups,
  } = useFetchGroups();
  const theme = useTheme();
  const PAGE_COUNT = 20;
  const computer = useMediaQuery(theme.breakpoints.up("lg"));
  const [openCreate, setOpenCreate] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchGroups({ entityName });
  }, [entityName]);

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("segmentation");

  return (
    <main>
      {fetchGroupsPending && !fetchGroupsDone ? (
        <div style={{ textAlign: "center", marginTop: "30vh" }}>
          <LoadingIcon style={{ height: 100, width: 100 }} />
        </div>
      ) : (
        <div>
          {openCreate && (
            <CreateGroupModal
              open={openCreate}
              onClose={() => setOpenCreate(false)}
              selectedGroup={openCreate}
              onSave={async (groupName, groupColor) => {
                await editGroup({
                  users: _.map(openCreate.users, "_id"),
                  entityName,
                  name: groupName,
                  color: groupColor,
                  rules: openCreate.rules,
                  groupId: _.get(openCreate, "_id"),
                });
                fetchGroups({ entityName });
                setOpenCreate(false);
              }}
            />
          )}
          {!groups || groups.length == 0 ? (
            <div
              style={{
                height: "80vh",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={require("assets/img/boxOpen.svg").default}
                style={{ marginBottom: 15, width: 65 }}
              />
              <div>
                {canEdit ? (
                  <Button
                    onClick={() => {
                      navigate(
                        parseCustomDomainUrl(
                          isCustomDomain,
                          entityName,
                          "/customers/segment/create"
                        )
                      );
                    }}
                  >
                    Create Your Segment
                  </Button>
                ) : (
                  <Box color={"#777"} fontWeight={600}>
                    No Segment
                  </Box>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="betweenRow" style={{ alignItems: "flex-start" }}>
                <div>
                  <div className={"title"}>
                    Segment<span className="label">({groupsCount})</span>
                  </div>
                  <Box
                    sx={(theme) => ({
                      color: theme.customColors.grey[500],
                      marginTop: "8px",
                      fontSize: "12px",
                    })}
                  >
                    Segmentation allows you to categorize user groups based on
                    the demographic details derived from on-chain data.
                  </Box>
                </div>
                {canEdit && (
                  <Button
                    onClick={() => {
                      navigate(
                        parseCustomDomainUrl(
                          isCustomDomain,
                          entityName,
                          "/customers/segment/create"
                        )
                      );
                    }}
                  >
                    Create New
                  </Button>
                )}
              </div>
              <div style={{ marginTop: 20 }}>
                <Grid container spacing={2}>
                  {_.map(groups, (group, i) => {
                    return (
                      <Grid item xs={12} sm={6} md={3} key={i}>
                        <GroupCardNew
                          groupInfo={group}
                          onEdit={setOpenCreate}
                          onRemove={removeGroup}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default GroupList;

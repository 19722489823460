import React, { useContext, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { PerformanceContext } from "./context/PerformanceProvider";

export default function RightChart({ totalStats, actionValue }) {
  const { formatMessage } = useIntl();

  const renderChartTitle = () => {
    if (actionValue.value === "clicks") {
      return "Clicked";
    }
    if (actionValue.value === "walletConnected") {
      return "Wallets Connected";
    }

    if (actionValue.value === "objectiveAchieved") {
      return "Objective Achieved";
    }

    if (actionValue.value === "open") {
      return "Opened";
    }
  };

  const color = ["#29CC6A", "#98DAFF", "#D6BBFB", "#FFE284", "#FFBCF0"];
  const chartData = totalStats?.map((item, index) => {
    return {
      ...item,
      value: item.count,
      itemStyle: { color: color[index] },
      label: {
        show: true,
        position: "inside-left",
        color: "#000",

        rich: {
          whiteBackground: {
            color: "#000",
            borderRadius: 10,
            fontSize: 10,
          },
        },
      },
    };
  });

  const data = useMemo(() => {
    return chartData?.sort((a, b) => a.value - b.value);
  }, [totalStats]);

  const offsetMap = {
    1: 103,
    2: 45,
    3: 26,
    4: 18,
    5: 12,
  };

  const option = {
    maintainAspectRatio: false,
    xAxis: {
      type: "value",
    },
    yAxis: {
      type: "category",

      show: false,
    },
    tooltip: {
      trigger: "axis",
      formatter: function (params) {
        const marker = params[0].marker;
        const label = params[0].name;
        const value = params[0].value;
        return `${marker}${label}: ${value}`;
      },
    },
    grid: {
      top: "0%",
      left: "10%",
    },
    series: [
      {
        type: "bar",
        barWidth: "80%",
        data,
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
        label: {
          show: true,
          position: "inside",
          color: "#000",
          formatter: function (params) {
            return params.name?.length > 30
              ? params.name.slice(0, 20) + "..."
              : params.name;
          },
          backgroundColor: "#fff",
          borderRadius: 10,
          offset: [10, offsetMap[chartData?.length]],
          padding: [5, 10],
        },
      },
    ],
  };
  const isEmpty = totalStats?.length === 0;
  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: "2px 0 ",
        width: "40%",
        height: "380px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          fontSize: 16,
          fontWeight: 500,
          color: "#555555",
          margin: "6px 16px",
        }}
      >
        {renderChartTitle()} by Campaign
      </Box>
      {isEmpty ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={require("assets/img/chartNoData.svg").default} />
          <Box color="#A5A5A5" mt="15px">
            {formatMessage({ id: "global.status.chartNoData" })}
          </Box>
        </Box>
      ) : (
        <ReactECharts
          option={option}
          autoResize={true}
          style={{ height: "100%" }}
        />
      )}
    </Box>
  );
}

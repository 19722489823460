import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "features/configure";
import Box from "@mui/material/Box";
import WebsiteTrafficSettingContent from "./WebsiteTrafficSettingContent";
import { Button, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import { useTheme } from "@mui/styles";
import { useOutletContext, useNavigate } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { get, isEmpty } from "lodash-es";

import { useIntl } from "react-intl";
import useMarketModule from "hooks/useMarketModule";
import { useOpenState } from "contexts/stepContext/Open.context";

const WebsiteTrafficSetting = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const { setOpen } = useOpenState();
  const [entityName, isCustomDomain] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const dispatchSnackbar = useDispatchSnackbar();
  const { attributionConnect } = useMarketModule();

  async function getObjectiveList() {
    try {
      setLoading(true);
      const { data } = await axios(
        apiUrl + `/api/trackingObjective/${entityName}`
      );

      console.log("data: ", data);

      setDataList(data);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getObjectiveList();
  }, []);

  const handleToCreateObjective = (targetId, row) => {
    if (!isEmpty(dataList)) {
      return setOpen({ upgrade: true });
    }
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/analytics/website/objective`
      )
    );
  };

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} mb="24px">
        <Box>
          <Box className={"title"}>
            {formatMessage({
              id: "analytics.websiteTraffic.settings.headerTitle",
            })}
          </Box>
          <Box
            sx={{
              fontSize: "14px",
              color: theme.customColors.grey[600],
              marginTop: "12px",
            }}
          >
            {formatMessage({
              id: "analytics.websiteTraffic.settings.headerDescription",
            })}
          </Box>
        </Box>
        <Box>
          <Button
            onClick={handleToCreateObjective}
            disabled={!attributionConnect}
          >
            {formatMessage({ id: "global.btn.createObjective" })}
          </Button>
        </Box>
      </Box>
      <Box>
        <WebsiteTrafficSettingContent
          dataList={dataList}
          loading={loading}
        ></WebsiteTrafficSettingContent>
      </Box>
    </>
  );
};

export default WebsiteTrafficSetting;

import React, { useState, useEffect } from "react";
import HoverImage from "features/metadesk/campaign/settings/component/hoverImage";
import Box from "@mui/material/Box";
import { useOutletContext, useNavigate } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import ObjectiveItem from "features/metadesk/campaign/settings/component/objectiveItem";
import { get, isEmpty } from "lodash-es";

import { useIntl } from "react-intl";
import useMarketModule from "hooks/useMarketModule";

const WebsiteTrafficSettingContent = ({ dataList, loading }) => {
  const [entityName, isCustomDomain] = useOutletContext();
  const { attributionConnect } = useMarketModule();

  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [noResult, setNoResult] = useState(true);

  const handleToSetTracking = (targetId, row) => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/settings/general/integration/attribution?from=analytics/settings/website-traffic`
      )
    );
  };

  const handleToCreateObjective = () => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/analytics/website/objective`
      )
    );
  };

  const renderDataList = () => {
    if (!attributionConnect) {
      return (
        <HoverImage
          originalImg={require("assets/img/website-traffic-placeholder.png")}
          hoverShowImg={require("assets/img/img_customization2.svg").default}
          hoverShowText={formatMessage({
            id: "analytics.websiteTraffic.setting.disableState",
          })}
          onClick={handleToSetTracking}
        ></HoverImage>
      );
    }
    if (loading) {
      return (
        <Box
          height={"60vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <LoadingComponent></LoadingComponent>
        </Box>
      );
    }

    if (isEmpty(dataList)) {
      return (
        <HoverImage
          originalImg={require("assets/img/website-traffic-placeholder.png")}
          hoverShowImg={require("assets/img/img_customization2.svg").default}
          hoverShowText={formatMessage({
            id: "analytics.websiteTraffic.settings.enableState",
          })}
          onClick={handleToCreateObjective}
          showButton={false}
        ></HoverImage>
      );
    }

    return (
      <>
        {dataList.map((objectiveItem) => (
          <ObjectiveItem key={objectiveItem._id} objectInfo={objectiveItem} />
        ))}
      </>
    );
  };

  return <>{renderDataList()}</>;
};

export default WebsiteTrafficSettingContent;

import React from "react";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";
import { StyledRegisterContainer } from "features/home/pages/Trial/RegisterForm.styles";
import { Box } from "@mui/material";
import { BorderRight } from "@mui/icons-material";
import { useOpenState } from "contexts/stepContext/Open.context";
import moment from "moment";

const NoAuth = ({}) => {
  const { formatMessage } = useIntl();
  const { openState, setOpen } = useOpenState();
  const icons = [
    "ic_services",
    "ic_marketing",
    "ic_analytics",
    "ic_settingOutline",
    "ic_advertise",
    "ic_customers",
  ];
  return (
    <StyledRegisterContainer>
      <Box style={{ fontWeight: 900, fontSize: 20 }} className="betweenRow">
        <img
          src={require("assets/img/header.svg").default}
          style={{ height: 30 }}
        />

        <Button
          size={SIZE.XL}
          color={COLOR.SECONDARY}
          variant={VARIANT.TEXT}
          style={{ fontWeight: 600 }}
          onClick={() => setOpen({ wallet: true })}
        >
          {formatMessage({ id: "global.btn.sign-in" })}
        </Button>
      </Box>
      <Box
        sx={{ maxWidth: "670px", textAlign: "center" }}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        margin="25vh auto"
      >
        <Box fontWeight={800} fontSize={"28px"} mb="10px">
          {formatMessage({ id: "portal.login.title" })}
        </Box>
        <Box
          fontWeight={500}
          fontSize={"20px"}
          mb="20px"
          maxWidth={"500px"}
          lineHeight={"30px"}
        >
          {formatMessage({ id: "portal.login.description" })}
        </Box>
        <Box className="betweenRow" mb={"40px"} width="100%">
          {icons.map((icon) => (
            <i
              key={icon}
              className={`meta-crm-icon-${icon} font-size-28`}
              style={{ color: "#7B61FF", opacity: 0.5 }}
            />
          ))}
        </Box>
        <Button
          size={SIZE.XL}
          color={COLOR.SECONDARY}
          style={{ width: 180, borderRadius: 40, fontWeight: 700 }}
          onClick={() => {
            setOpen({ wallet: true });
          }}
        >
          {formatMessage({ id: "global.btn.sign-in" })}
        </Button>
        <Button
          size={SIZE.XL}
          color={COLOR.SECONDARY}
          variant={VARIANT.TEXT}
          style={{ width: 180, marginTop: 10 }}
          onClick={() => window.open("https://metacrm.inc")}
        >
          {formatMessage({ id: "portal.signIn.btn" })}
        </Button>
      </Box>
      <Box
        position={"fixed"}
        style={{
          bottom: "20px",
          left: "50%",
          transform: "translate(-50%,-50%)",
          fontSize: 12,
          fontWeight: 400,
          color: "#555555",
          textAlign: "center",
        }}
      >
        <Box
          style={{
            fontSize: 10,
            opacity: 0.7,
          }}
        >
          v{process.env.REACT_APP_VERSION} (
          {moment(process.env.REACT_APP_DEPLOYED_AT).format("YYYY.MM.DD")})
        </Box>
        Copyright© 2023 - MetaCRM. All Rights Reserved
      </Box>
    </StyledRegisterContainer>
  );
};

export default NoAuth;

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { apiUrl } from "features/configure";
import HoverImage from "features/metadesk/campaign/settings/component/hoverImage";
import { parseCustomDomainUrl } from "features/helpers/utils";
import OnlyTabs from "@metacrm/metacrm-material-ui/dist/OnlyTabs";
import axios from "axios";
import { get, isEmpty } from "lodash-es";
import TrackingModal from "features/metadesk/campaign/campaignList/component/TrackingModal";

import { useOutletContext, useNavigate } from "react-router-dom";

import { useIntl } from "react-intl";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import CampaignItem from "features/metadesk/campaign/settings/component/campaignItem";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import CustomPagination from "components/CustomPagination/CustomPagination";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import useMarketModule from "hooks/useMarketModule";

const AttributionLinkTableContent = () => {
  const { attributionConnect } = useMarketModule();
  const PAGE_COUNT = 6;
  const [entityName, isCustomDomain] = useOutletContext();
  const { formatMessage } = useIntl();
  const [dataList, setDataList] = useState([]);
  const navigate = useNavigate();
  const [attributionStatus, setAttributionStatus] = useState(4);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [update, setUpdate] = useState(false);
  const [noResult, setNoResult] = useState(true);
  const dispatchSnackbar = useDispatchSnackbar();
  const [trackingLinksModal, setTrackingLinksModal] = useState({
    isOpen: false,
    data: [],
    campaignName: "",
    campaignId: "",
  });

  const handleToCreateTracker = (targetId, row) => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/analytics/settings/attribution-link/create-campaign`
      )
    );
  };

  const handleToSetTracking = (targetId, row) => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/settings/general/integration/attribution?from=analytics/settings/attribution-link`
      )
    );
  };

  const handleChangeStatus = (event, newValue) => {
    setAttributionStatus(newValue);
  };

  const tabsStatus = [
    {
      id: "draft",
      label: "Draft",
    },
    {
      id: "scheduled",
      label: "Scheduled",
    },
    {
      id: "ongoing",
      label: "Ongoing",
    },
    {
      id: "done",
      label: "Done",
    },
    {
      id: "",
      label: "All",
    },
  ];

  async function getCampaignList() {
    try {
      setLoading(true);
      const { data, headers } = await axios(
        `${apiUrl}/api/campaign/${entityName}?page=${page}&sort=&order=&status=${
          tabsStatus[attributionStatus].id
        }&search=${""}&promotype=tracker&limit=${PAGE_COUNT}`
      );
      if (tabsStatus[attributionStatus].id === "" && isEmpty(data)) {
        setNoResult(true);
      }
      if (!isEmpty(data)) {
        setNoResult(false);
      }

      setTotalCount(get(headers, "x-total-count", 0));

      // setData(data);
      setDataList(data);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCampaignList();
  }, [attributionStatus, page]);

  // 當切換狀態或搜尋時，將頁數回到第一頁，並重新取得資料
  useEffect(() => {
    setPage(1);
    setUpdate(true);
  }, [attributionStatus]);

  // 當切換頁數時，重新取得資料
  useEffect(() => {
    setUpdate(true);
  }, [page]);

  // 用 update 來判斷是否需要更新
  useEffect(() => {
    if (!update) return;
    getCampaignList();
    setUpdate(false);
  }, [update]);

  const handleOpenTrackingLinksModal = (data) => {
    setTrackingLinksModal({
      isOpen: true,
      data: data.trackingLinks,
      campaignName: data.campaignName,
      campaignId: data._id,
      promotype: "tracker",
    });
  };

  const renderDataList = () => {
    if (!attributionConnect) {
      return (
        <HoverImage
          originalImg={require("assets/img/attribution_empty.jpg")}
          hoverShowImg={require("assets/img/img_customization2.svg").default}
          hoverShowText={formatMessage({
            id: "analytics.attributionLink.setting.disableState",
          })}
          onClick={handleToSetTracking}
        ></HoverImage>
      );
    }
    if (noResult) {
      if (loading) {
        return (
          <Box
            height={"60vh"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <LoadingComponent></LoadingComponent>
          </Box>
        );
      }
      return (
        <HoverImage
          originalImg={require("assets/img/attribution_empty.jpg")}
          hoverShowImg={require("assets/img/img_customization.png")}
          hoverShowText={formatMessage({
            id: "analytics.attributionLink.settings.enableState",
          })}
          onClick={handleToCreateTracker}
          showButton={false}
        ></HoverImage>
      );
    }
    return (
      <>
        <Box mb={2}>
          <OnlyTabs
            tabs={tabsStatus}
            value={attributionStatus}
            onChange={handleChangeStatus}
          />
        </Box>
        {!isEmpty(dataList) ? (
          <>
            {dataList.map((row) => (
              <CampaignItem
                key={row.id}
                campaignInfo={row}
                handleToCreateTracker={handleToCreateTracker}
                onHandleOpenTrackingLinksModal={handleOpenTrackingLinksModal}
              ></CampaignItem>
            ))}

            <CustomPagination
              shape="rounded"
              PAGE_COUNT={PAGE_COUNT}
              count={totalCount}
              page={page}
              setPage={(v) => setPage(v)}
            />

            <TrackingModal
              isTracker={true}
              open={trackingLinksModal.isOpen}
              onClose={() =>
                setTrackingLinksModal({
                  isOpen: false,
                  data: [],
                  campaignName: "",
                  campaignId: "",
                })
              }
              data={trackingLinksModal.data}
              campaignName={trackingLinksModal.campaignName}
              campaignId={trackingLinksModal.campaignId}
              promotype={trackingLinksModal.promotype}
            />
          </>
        ) : (
          <>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              mt="6%"
            >
              <img src={require("assets/img/campaignNoData.png")} />
              <p style={{ color: "#555555", width: "250px" }}>
                {formatMessage({ id: "campaigns.emptyState.description" })}
              </p>
            </Box>
          </>
        )}
      </>
    );
  };
  return <>{renderDataList()}</>;
};

export default AttributionLinkTableContent;

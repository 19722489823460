import { useState } from "react";
import { Box } from "@mui/material";
import { AgentAnalytics } from "./AgentAnalytics";
import { TicketAnalytics } from "./TicketAnalytics";

import MetaCRMDateRange from "components/DateRange/MetaCRMDateRange";

const ServiceReportPage = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  return (
    <Box>
      <div className="title">Ticket Distribution</div>

      <Box className="card" mb={"20px"}>
        <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
          <MetaCRMDateRange
            startDate={startDate}
            endDate={endDate}
            custom
            onChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
        </Box>

        <TicketAnalytics startDate={startDate} endDate={endDate} />
      </Box>

      <AgentAnalytics startDate={startDate} endDate={endDate} />
    </Box>
  );
};

export default ServiceReportPage;

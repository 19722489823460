import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "features/common/redux/actions";
import { makeStyles, withStyles } from "@mui/styles";
import _ from "lodash";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { apiUrl } from "features/configure";
import axios from "axios";
import { Popover } from "@mui/material";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({}));
const Comment = ({ row, selectedTicket, entityName, comment, setComment }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [newComment, setNewComment] = useState(null);
  const [anchorComment, setAnchorComment] = useState(null);
  const updateValue = (row ? newComment : comment) || "";
  const limitLength = 150;
  const updateComment = async (e) => {
    if (updateValue.length > limitLength) return;
    await axios.post(`${apiUrl}/api/ticket/update/comment`, {
      entityName,
      ticketId: _.get(selectedTicket, "_id", ""),
      comment: updateValue,
    });
    dispatch(
      enqueueSnackbar({
        message: formatMessage({
          id: "ticketDetail.ticketInfo.updateCommentSuccess",
        }),
        options: {
          variant: "info",
        },
      })
    );
    if (row) {
      setComment(newComment);
      setAnchorComment(null);
      setNewComment("");
      e.stopPropagation();
    }
  };

  const renderCommentInput = (value, setValue) => {
    return (
      <div
        style={{ minWidth: 193, border: "1px solid #D6d6d6", borderRadius: 4 }}
      >
        <InputField
          minRows={2}
          placeholder={formatMessage({
            id: "ticketDetail.ticketInfo.commentInputPlaceholder",
          })}
          multiline={true}
          onClick={(e) => e.stopPropagation()}
          errors={
            updateValue.length > limitLength && `Exceeds ${limitLength} limit`
          }
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <div
          style={{
            borderTop: "1px solid #D6d6d6",
            padding: "6px 12px",
            textAlign: "right",
          }}
        >
          <IconButton onClick={updateComment}>
            <i className="meta-crm-icon-ic-up-arrow-circle font-size-18" />
          </IconButton>
        </div>
      </div>
    );
  };

  if (row) {
    return (
      <div>
        <Popover
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          open={Boolean(anchorComment)}
          anchorEl={anchorComment}
          onClose={(e) => {
            setAnchorComment(null);
            setNewComment("");
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <div onClick={(e) => e.stopPropagation()}>
            {renderCommentInput(newComment, setNewComment)}
          </div>
        </Popover>
        <IconButton
          onClick={(e) => {
            setAnchorComment(e.currentTarget);
            e.stopPropagation();
          }}
        >
          <i
            className="meta-crm-icon-ic_messageAdd font-size-18"
            style={{ color: "#A5A5A5" }}
          />
        </IconButton>
      </div>
    );
  } else {
    return renderCommentInput(comment, setComment);
  }
};

export default Comment;

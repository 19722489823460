import React, { memo, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, useTheme } from "@mui/material";
import Back from "features/metadesk/components/Back";
import {
  StyledContainerTitle,
  StyledWhiteCardContainer,
} from "../createCampaign/component/CampaignCreation.styles";
import _ from "lodash";
import { StyledContentContainer } from "../../referral/Referral.styles";
import Objective from "../createCampaign/component/Objective";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import {
  Button,
  VARIANT,
  AddButton,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { alertInfo, alertServerError } from "features/helpers/utils";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useNavigate, useOutletContext } from "react-router";
import useNavigateEntity from "hooks/useNavigateEntity";
import { Loading } from "features/common";
import { useOpenState } from "contexts/stepContext/Open.context";
import useMarketModule from "hooks/useMarketModule";

const defaultValues = {
  objectives: [],
};

function TrafficReportObjective() {
  const theme = useTheme();
  const [entityName] = useOutletContext();
  const [objectivesData, setObjectivesData] = useState([]);

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors: errorsInfo, isValid },
  } = methods;

  const { append, replace, remove } = useFieldArray({
    control,
    name: "objectiveList",
  });
  const [formData, setFormData] = useState(defaultValues);
  console.log("formData: ", formData);
  const { setOpen } = useOpenState();
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const { navigateEntity } = useNavigateEntity();
  const { objectives } = formData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { marketingModule } = useMarketModule();
  const isMultiObjectives = _.get(
    marketingModule,
    "attribution.multipleObjectives"
  );
  const isReadOnly = !_.isEmpty(objectivesData);
  useEffect(async () => {
    try {
      setLoading(true);
      const result = await axios.get(
        apiUrl + `/api/trackingObjective/${entityName}`
      );
      const data = _.get(result, "data", []);

      setObjectivesData(data);

      if (_.isEmpty(data))
        setFormData({ objectives: [{ type: "contract", _id: uuidv4() }] });
      else {
        const convertedData = _.map(data, (objectiveData) =>
          convertObjective(objectiveData)
        );
        setFormData({ objectives: convertedData });
        replace(convertedData);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const convertObjective = (data) => {
    return {
      chainId: data.chainId,
      smartContractAddress: data.contractAddress,
      method: data.eventName,
      smartContractABI: data.eventABI,
      type: "contract",
      _id: data._id,
    };
  };

  const handleCreate = async () => {
    try {
      await axios.post(apiUrl + `/api/trackingObjective/${entityName}`, {
        objectives: _.map(formData?.objectives, (objective) => ({
          chainId: objective.chainId,
          contractAddress: objective.smartContractAddress,
          eventName: objective.method,
          eventABI: objective.smartContractABI,
        })),
      });
      dispatch(alertInfo("Create Objective Success"));
      navigateEntity("/analytics/website");
    } catch (err) {
      dispatch(alertServerError(err));
    }
  };
  const handleDeleteObjective = (targetId, objectiveIndex) => {
    const newArr = objectives.filter((item) => item._id !== targetId);
    setFormData({ objectives: newArr });
    remove(objectiveIndex);
  };

  return (
    <Box position={"relative"}>
      <Loading open={loading} fullScreen={true} />
      <FormProvider {...methods}>
        <Back />
        <Box className="title" mb="20px">
          Create your objective
        </Box>
        <StyledWhiteCardContainer>
          <StyledContainerTitle>
            Please select your objective
          </StyledContainerTitle>
          <StyledContentContainer>
            {_.map(objectives, (data, i) => {
              return (
                <Objective
                  objectiveIndex={i}
                  key={i}
                  onDelete={objectives.length > 1 && handleDeleteObjective}
                  readonly={isReadOnly}
                  formData={formData}
                  isTargetEvent={true}
                  setFormData={(values) => {
                    setFormData({
                      ...formData,
                      ...values,
                    });
                  }}
                ></Objective>
              );
            })}

            {!isReadOnly && (
              <Box sx={{ borderTop: "1px dashed #d6d6d6", mt: 2, pt: 2 }}>
                <AddButton
                  onClick={() => {
                    if (isMultiObjectives) {
                      setFormData({
                        objectives: [
                          ...objectives,
                          { type: "contract", _id: uuidv4() },
                        ],
                      });
                    } else {
                      setOpen({ upgrade: true });
                    }
                  }}
                >
                  {formatMessage({
                    id: "campaignBuild.thirdStep.addObjective.btn",
                  })}
                </AddButton>
              </Box>
            )}
          </StyledContentContainer>
        </StyledWhiteCardContainer>
        {!isReadOnly && (
          <FixedButton>
            <Button
              variant={VARIANT.TEXT}
              style={{ width: 144 }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit(handleCreate())}
              style={{ width: 144 }}
              disabled={!isValid}
            >
              Create
            </Button>
          </FixedButton>
        )}
      </FormProvider>
    </Box>
  );
}

export default memo(TrafficReportObjective);

import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import axios from "axios";
import { apiUrl, importTypes } from "features/configure";
import _ from "lodash";
import classNames from "classnames";
import { IconButton, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import {
  StyledSearchBar,
  StyledImportPopoverFileName,
  StyledBadge,
  StyledImportPopoverLine,
} from "./ImportDataPopover.styles";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

import { formatDate, getHexImg } from "features/helpers/utils";
import { useSelector } from "react-redux";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";

import ContractLabelEditor from "../ContractLabelEditor";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import { getShortAddress } from "features/helpers/utils";
import DateRangeSelect from "components/DateRange/DateRangeSelect";
import LoadingIcon from "components/LoadingComponent/LoadingIcon";

const ImportContractSelect = ({
  entityName,
  setError,
  setIsUploading,
  uploadData,
  setUploadData,
  isUploading,
  onSelect,
  editable,
  excludeChains = [],
  background,
  label,
  hideTypeSelect = false,
}) => {
  const chainList = useSelector(selectEntityChainList);

  let [lastMonth, lastWeek] = [new Date(), new Date()];
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  lastWeek.setDate(lastWeek.getDate() - 7);

  const { formatMessage } = useIntl();
  const [openAddressSearch, setOpenAddressSearch] = useState(false);
  const chains =
    excludeChains.length > 0
      ? _.filter(chainList, (obj) => {
          return !excludeChains.includes(obj.value);
        })
      : chainList;
  const [importChain, setImportChain] = useState(_.get(chains, "[0].value"));
  const [importType, setImportType] = useState(_.get(importTypes, "[2].value"));
  const [importAddress, setImportAddress] = useState("");
  const [importContract, setImportContract] = useState("");
  const [interactionRange, setInteractionRange] = useState({
    startDate: lastMonth,
    endDate: new Date(),
    key: "selection",
  });
  const [selectedRange, setSelectedRange] = useState("month");
  const dropdownRef = useRef(null);

  const findContract = useCallback(async () => {
    try {
      if (importAddress < 20 || uploadData.contract) return;
      setOpenAddressSearch(true);
      setImportContract("");
      const result = await axios.post(
        apiUrl + `/api/userSource/${entityName}/contract/info`,
        {
          address: importAddress,
          chainId: importChain,
        }
      );
      const data = result.data;
      setImportContract({
        ...data,
        icon: data.icon || getHexImg(data.address),
        type: importType,
      });
    } catch (err) {
      setOpenAddressSearch(false);
      setError(_.get(err, "response.data.error", "Error"));
    }
  }, [
    entityName,
    importAddress,
    importChain,
    importType,
    setError,
    uploadData.contract,
  ]);

  const chooseContract = useCallback(
    async (e) => {
      if (!importContract) return;

      try {
        e?.stopPropagation();
        setIsUploading(true);

        const contractData = {
          ...importContract,
          range:
            importType === "contract-interactor"
              ? [
                  interactionRange.startDate.toISOString(),
                  interactionRange.endDate.toISOString(),
                ].join(";")
              : undefined,
        };

        setOpenAddressSearch(false);
        setUploadData({ contract: contractData });
        if (onSelect) await onSelect(contractData);

        setError("");
      } catch (err) {
        setError(_.get(err, "response.data.error", "Error"));
      } finally {
        setIsUploading(false);
      }
    },
    [
      importContract,
      importType,
      interactionRange,
      onSelect,
      setError,
      setIsUploading,
      setUploadData,
    ]
  );

  const selectRange = (option) => {
    if (option === selectedRange || isUploading) return;

    if (typeof option === "string") {
      setSelectedRange(option);
      setInteractionRange(
        {
          month: {
            startDate: lastMonth,
            endDate: new Date(),
            key: "selection",
          },
          week: {
            startDate: lastWeek,
            endDate: new Date(),
            key: "selection",
          },
          custom: {
            startDate: undefined,
            endDate: undefined,
            key: "selection",
          },
        }[option]
      );
    } else {
      setSelectedRange("custom");
      setInteractionRange(option);
    }
  };

  useEffect(() => {
    const checkClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        event.relatedTarget !== dropdownRef.current
      ) {
        setOpenAddressSearch(false);
      }
    };

    document.addEventListener("mousedown", checkClickOutside);

    return () => {
      document.removeEventListener("mousedown", checkClickOutside);
    };
  }, []);

  useEffect(() => {
    findContract();
  }, [findContract, importAddress, importChain, importType]);

  useEffect(() => {
    if (interactionRange.startDate && interactionRange.endDate)
      chooseContract();
  }, [interactionRange.startDate, interactionRange.endDate]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <CustomDropdown
            data={chains}
            size={SIZE.LARGE}
            disabled={uploadData.contract}
            fullWidth={true}
            label={
              <div>
                {formatMessage({ id: "importByContract.labelName.blockchain" })}
                <span className="required">*</span>
              </div>
            }
            border={true}
            value={importChain}
            onSelect={(e) => {
              setImportChain(e.target.value);
            }}
          />
        </Box>
        {!hideTypeSelect ? (
          <Box sx={{ flex: 1 }}>
            <CustomDropdown
              data={importTypes}
              size={SIZE.LARGE}
              disabled={uploadData.contract}
              fullWidth={true}
              label={
                <div>
                  {formatMessage({
                    id: "importByContract.labelName.importType",
                  })}
                  <span className="required">*</span>
                </div>
              }
              border={true}
              value={importType}
              onSelect={(e) => {
                setImportType(e.target.value);
              }}
            />
          </Box>
        ) : null}
      </Box>
      <div className="space" />
      <div style={{ position: "relative" }}>
        <InputField
          disabled={uploadData.contract}
          size={SIZE.LARGE}
          startAdornment={
            <i className="meta-crm-icon-ic_search font-size-14" />
          }
          placeholder="Search by Token / NFT / Smart Contract Address"
          onFocus={findContract}
          value={importAddress}
          onChange={(e) => {
            setImportAddress(e.target.value);
          }}
        />
        {openAddressSearch && (
          <StyledSearchBar ref={dropdownRef}>
            {importContract ? (
              <div className="startRow" onClick={chooseContract}>
                <img src={importContract.icon} className="avatar" />
                <div>
                  <div>{importContract.name}</div>
                  <div className="memo">
                    {getShortAddress(importContract.address)}
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ padding: 5, textAlign: "center" }}>
                Fetching...
                <LoadingIcon className="smIcon" />
              </div>
            )}
          </StyledSearchBar>
        )}
      </div>
      <div className="space" />
      {uploadData.contract && (
        <div>
          <StyledImportPopoverFileName
            style={{ background: background || "#f8f8f8" }}
          >
            {label && <div style={{ marginBottom: 16 }}>{label}</div>}
            <div className="betweenRow">
              <ContractLabelEditor
                data={uploadData.contract}
                editable={editable}
                updateData={async ({ data, editName, images }) => {
                  const postBody = {
                    chainId: data.chainId,
                    address: data.address,
                  };
                  const newData = { ...uploadData };
                  if (editName) {
                    postBody.name = editName;
                    _.set(newData, "contract.name", editName);
                  }
                  if (images) {
                    postBody.icon = images[0];
                    _.set(newData, "contract.icon", images[0]);
                  }
                  await axios.post(
                    apiUrl +
                      `/api/userSource/${entityName}/contract/updateInfo`,
                    postBody
                  );

                  setUploadData(newData);
                }}
              />
              <IconButton
                onClick={() => {
                  setUploadData({});
                  setError("");
                }}
                size={16}
              >
                <i className="meta-crm-icon-ic_trash font-size-12" />
              </IconButton>
            </div>
          </StyledImportPopoverFileName>
        </div>
      )}
      {!hideTypeSelect && importType === "contract-interactor" && (
        <div>
          <StyledImportPopoverLine />
          <div>Time Range</div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap: "0.75rem",
              my: "1rem",
              mx: "0.5rem",
            }}
          >
            <i className="meta-crm-icon-ic_calendar font-size-16" />
            {["month", "week"].map((t) => (
              <button
                key={t}
                style={{
                  background: "transparent",
                  border: "none",
                  borderRadius: "20px",
                  padding: 0,
                }}
                onClick={() => selectRange(t)}
              >
                <StyledBadge
                  className={classNames(selectedRange === t && "active")}
                >
                  Last {t}
                </StyledBadge>
              </button>
            ))}
            <DateRangeSelect
              custom={
                <StyledBadge
                  className={classNames(selectedRange === "custom" && "active")}
                >
                  {selectedRange === "custom" &&
                  interactionRange.startDate &&
                  interactionRange.endDate ? (
                    <>
                      {formatDate(interactionRange.startDate, true)} ~{" "}
                      {formatDate(interactionRange.endDate, true)}
                    </>
                  ) : (
                    "Customize"
                  )}
                </StyledBadge>
              }
              disabled={isUploading}
              defaultInputRanges={null}
              value={[interactionRange]}
              onChange={(value) => selectRange(value.pop())}
              onClose={() => {
                if (
                  importType === "contract-interactor" &&
                  selectedRange === "custom" &&
                  !(interactionRange.startDate && interactionRange.endDate)
                ) {
                  selectRange("month");
                }
              }}
            />
          </Box>
        </div>
      )}
    </div>
  );
};

export default ImportContractSelect;

import React, { useContext, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { CampaignCreationContext } from "./context/CampaignCreationProvider";

import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import moment from "moment";

import {
  StyledContainerTitle,
  StyledInputContainer,
  StyledWhiteCardContainer,
} from "./component/CampaignCreation.styles";

import { useIntl } from "react-intl";
import DateTimePicker from "features/metadesk/campaign/createCampaign/component/DateTimePicker";

const isToday = (date) => {
  console.log("date: ", date);
  if (!date) return false; // 確保 date 有值
  // 如果 date 是字符串，先將其轉換為 Date 對象
  const parsedDate = typeof date === "string" ? new Date(date) : date;
  const today = new Date();
  return (
    parsedDate.getDate() === today.getDate() &&
    parsedDate.getMonth() === today.getMonth() &&
    parsedDate.getFullYear() === today.getFullYear()
  );
};

const isSameDay = (start, end) => {
  if (!start || !end || !(start instanceof Date) || !(end instanceof Date)) {
    return false; // 如果 start 或 end 無效，返回 false
  }
  return (
    start.getDate() === end.getDate() &&
    start.getMonth() === end.getMonth() &&
    start.getFullYear() === end.getFullYear()
  );
};

export default function Schedule({
  startFrom,
  endAt,
  onChangeStartFrom,
  onChangeEndAt,
  isReadOnly,
  noContainer = false,
}) {
  const { formatMessage } = useIntl();

  const renderInputContainerContent = () => {
    return (
      <>
        <StyledInputContainer>
          <p>
            <span style={{ marginRight: "4px" }}>
              {formatMessage({ id: "global.labelName.website" })}
            </span>
            <IconTooltip
              customColor="#a5a5a5"
              content={formatMessage({
                id: "campaign.startDate.tooltip",
              })}
            />
          </p>
          {isReadOnly ? (
            <Box>{moment(startFrom).format("DD MMM YYYY , HH:mm")}</Box>
          ) : (
            <DateTimePicker
              value={startFrom}
              showTimeSelect
              onChange={onChangeStartFrom}
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              minDate={new Date()} // 禁用今天以前的日期
              minTime={!startFrom || isToday(startFrom) ? new Date() : null} // 只有當選擇了日期且是今天時，才應用 minTime
              maxTime={
                !startFrom || isToday(startFrom)
                  ? new Date(new Date().setHours(23, 59, 59, 999))
                  : null
              } // 只有當選擇了日期且是今天時，才應用 maxTime
              dateFormat="MM/d/yyyy h:mm aa"
              showIcon
              icon={
                <Box
                  component="i"
                  color={(theme) => theme.customColors.grey[700]}
                  className="meta-crm-icon-ic_calendar font-size-18"
                />
              }
              placeholderText={formatMessage({
                id: "campaignBuild.secondStep.expirationDate",
              })}
            />
          )}
        </StyledInputContainer>

        <StyledInputContainer>
          <p>
            <span style={{ marginRight: "4px" }}>
              {formatMessage({ id: "global.labelName.endDate" })}
            </span>
            <IconTooltip
              customColor="#a5a5a5"
              content={formatMessage({
                id: "global.toolTip.endDate",
              })}
            />
          </p>

          {isReadOnly ? (
            <Box>{moment(endAt).format("DD MMM YYYY , HH:mm")}</Box>
          ) : (
            <DateTimePicker
              value={endAt}
              showTimeSelect
              onChange={onChangeEndAt}
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MM/d/yyyy h:mm aa"
              minDate={startFrom || new Date()} // 禁用開始日期以前的日期
              minTime={(() => {
                if (endAt && isSameDay(startFrom, endAt)) {
                  // 如果結束日期等於開始日期，則時間必須大於開始時間
                  return new Date(startFrom);
                } else if (!endAt || (!startFrom && isToday(endAt))) {
                  //  如果開始時間不存在，限制今天的當前時間
                  return new Date(); // 設置 minTime 為當前時間
                } else {
                  // 如果結束日期不同，設置 minTime 為當天的00:00
                  return new Date(new Date().setHours(0, 0, 0, 0));
                }
              })()}
              maxTime={new Date(new Date().setHours(23, 59, 59, 999))} // 確保返回 Date 對象，設置最大時間為當天23:59
              showIcon
              icon={
                <Box
                  component="i"
                  color={(theme) => theme.customColors.grey[700]}
                  className="meta-crm-icon-ic_calendar font-size-18"
                />
              }
              placeholderText={formatMessage({
                id: "campaignBuild.secondStep.endDate",
              })}
            />
          )}
        </StyledInputContainer>
      </>
    );
  };

  return (
    <>
      {noContainer ? (
        <>{renderInputContainerContent()}</>
      ) : (
        <>
          {" "}
          <StyledWhiteCardContainer sx={{ marginBottom: "20px" }}>
            <StyledContainerTitle>
              {formatMessage({
                id: "engagementCampaign.trackSetting.destination.subtitle",
              })}
            </StyledContainerTitle>
            <div className="mhr" style={{ margin: 0 }}></div>
            {renderInputContainerContent()}
          </StyledWhiteCardContainer>
        </>
      )}
    </>
  );
}

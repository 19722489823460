import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";

import HoverImage from "features/metadesk/campaign/settings/component/hoverImage";
import { parseCustomDomainUrl } from "features/helpers/utils";
import OnlyTabs from "@metacrm/metacrm-material-ui/dist/OnlyTabs";

import { useOutletContext, useNavigate } from "react-router-dom";
import {
  StatusLabel,
  SIZE,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/StatusLabel";

import { useIntl } from "react-intl";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { StyledItemTitle } from "./campaignItem.styles";
import moment from "moment";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { getUserAvatarIcon } from "features/metadesk/utils";

const getChainNameById = (chainList, id) => {
  const chain = chainList.find((chain) => chain.id === id);
  return chain ? chain.name : null; // 如果找到則返回 name，否則返回 null
};
const CampaignItem = ({ campaignInfo, onHandleOpenTrackingLinksModal }) => {
  const navigate = useNavigate();
  const [entityName, isCustomDomain] = useOutletContext();
  const theme = useTheme();
  const chainList = useSelector(selectEntityChainList);
  const icon = getUserAvatarIcon(campaignInfo?.creator?._id, theme);

  const renderStatus = () => {
    if (campaignInfo?.status === "draft") {
      return <StatusLabel color={COLOR.SECONDARY}>Draft</StatusLabel>;
    }
    if (campaignInfo?.status === "ongoing") {
      return <StatusLabel color={COLOR.SUCCESS}>Ongoing</StatusLabel>;
    }

    if (campaignInfo?.status === "done") {
      return <StatusLabel color={COLOR.INFO}>Done</StatusLabel>;
    }

    if (campaignInfo?.status === "scheduled") {
      return <StatusLabel color={COLOR.PRIMARY}>Scheduled</StatusLabel>;
    }
  };

  const handleCampaignClick = () => {
    if (campaignInfo?.status === "ongoing" || campaignInfo?.status === "done") {
      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          `/analytics/attribution/utmLinks/${campaignInfo?._id}`
        )
      );
    }
    if (campaignInfo?.status === "draft") {
      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          `/analytics/settings/attribution-link/create-campaign/${campaignInfo?._id}`
        )
      );
    }

    if (campaignInfo?.status === "scheduled") {
      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          `/analytics/settings/attribution-link/campaign/edit/${campaignInfo?._id}`
        )
      );
    }
  };

  const handleLinkClick = (e) => {
    e.stopPropagation();
    onHandleOpenTrackingLinksModal(campaignInfo);
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "8px",
        marginBottom: "20px",
        "& .campaignName": {
          color: theme.customColors.grey[700],
        },
        "&:hover": {
          cursor: "pointer",
          outline: `2px solid ${theme.customColors.purple[500]}`,
          "& .campaignName": {
            color: theme.customColors.purple[500],
          },
        },
      }}
      onClick={handleCampaignClick}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "18px 20px",
          borderBottom: `1px solid ${theme.customColors.grey[300]}`,
        }}
      >
        <Box marginRight={"8px"}>{renderStatus()}</Box>
        <Box className="campaignName" sx={{ fontWeight: 700 }}>
          {campaignInfo?.name}
        </Box>
      </Box>
      <Box padding="16px 18px">
        <Grid container spacing={2.5}>
          <Box item component={Grid} xs={12} md={3}>
            <StyledItemTitle>Tracking Link</StyledItemTitle>
            {campaignInfo?.status !== "draft" ? (
              <Box
                color={theme.customColors.purple[500]}
                sx={{ fontWeight: "700", cursor: "pointer" }}
                onClick={handleLinkClick}
              >
                {campaignInfo?.trackingLinks?.length || 0}
              </Box>
            ) : (
              <>--</>
            )}
          </Box>

          <Box item component={Grid} xs={12} md={3}>
            <StyledItemTitle>Website URL</StyledItemTitle>
            <Box>{campaignInfo.websiteUrl}</Box>
          </Box>

          <Box item component={Grid} xs={6} md={3}>
            <StyledItemTitle>Start Date</StyledItemTitle>
            <Box>
              {campaignInfo?.startFrom
                ? moment(campaignInfo.startFrom).format("DD MMM YYYY , HH:mm")
                : "--"}
            </Box>
          </Box>

          <Box item component={Grid} xs={6} md={3}>
            <StyledItemTitle>End Date</StyledItemTitle>
            <Box>
              {campaignInfo?.endAt
                ? moment(campaignInfo.endAt).format("DD MMM YYYY , HH:mm")
                : "--"}
            </Box>
          </Box>

          <Box item component={Grid} xs={6} md={3}>
            <StyledItemTitle>Objective</StyledItemTitle>
            <Box>{campaignInfo.objective?.name || "--"}</Box>
          </Box>

          <Box item component={Grid} xs={6} md={3}>
            <StyledItemTitle>Chain</StyledItemTitle>
            <Box>
              {getChainNameById(chainList, campaignInfo?.objective?.chainId) ||
                "--"}
            </Box>
          </Box>

          <Box item component={Grid} xs={6} md={3}>
            <StyledItemTitle>Contract Address</StyledItemTitle>
            <Box>{campaignInfo.objective?.contractAddress || "--"}</Box>
          </Box>

          <Box item component={Grid} xs={6} md={3}></Box>

          <Box item component={Grid} xs={6} md={3}>
            <StyledItemTitle>Campaign Description</StyledItemTitle>
            <Box>{campaignInfo?.description}</Box>
          </Box>
        </Grid>

        <Box
          display={"flex"}
          alignItems={"center"}
          marginTop={"16px"}
          color={theme.customColors.grey[600]}
        >
          <Box sx={{ fontSize: "14px" }}>Editor:</Box>
          <Box
            component={"img"}
            src={require(`assets/img/${icon}`)}
            sx={{ width: "16px", height: "16px", ml: "4px", mr: "4px" }}
          />
          <Box sx={{ fontSize: "12px" }}>
            {campaignInfo?.creator?.name || "--"}
          </Box>
          <Box mx={"4px"}>,</Box>
          <Box sx={{ fontSize: "12px" }}>
            {campaignInfo.updated
              ? moment(campaignInfo.updated).format("YYYY/MM/DD")
              : "--"}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignItem;

import React, { useContext, useEffect, useRef, useState } from "react";
import { InputField, SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import { PerformanceContext } from "./context/PerformanceProvider";
import { useIntl } from "react-intl";
import DateRangeSelect from "components/DateRange/DateRangeSelect";
import { Box } from "@mui/material";
import CustomToggle, {
  StyledToggleButton,
} from "components/CustomToggle/CustomToggle";

import moment from "moment";
import MetaCRMDateRange from "components/DateRange/MetaCRMDateRange";

export default function PerformanceSearch() {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setSearch,
    search,
    isAdvanced,
  } = useContext(PerformanceContext);

  const { formatMessage } = useIntl();
  const [input, setInput] = useState("");
  const handleChange = (e) => {
    setSearch(input);
  };
  useEffect(() => {
    setInput(search);
  }, [search]);

  return (
    <Box
      display={"flex"}
      sx={{
        backgroundColor: "white",
        padding: "8px 16px",
      }}
    >
      <Box sx={{ flex: "1", maxWidth: "500px" }}>
        <InputField
          sx={{
            width: "100%",
          }}
          placeholder={
            isAdvanced
              ? formatMessage({
                  id: "campaignPerformance.trackerTab.advancedSearchBar.placeHolder",
                })
              : formatMessage({
                  id: "campaignPerformance.trackerTab.normalSearchBar.placeHolder",
                })
          }
          onChange={(e) => setInput(e.target.value)}
          onBlur={handleChange}
          onEnter={handleChange}
          onClean={() => setSearch("")}
          name="test"
          value={input}
          type={"search"}
          size={SIZE.MEDIUM}
        />
      </Box>
      <MetaCRMDateRange
        onChange={(startDate, endDate) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        custom
        startDate={startDate}
        endDate={endDate}
      />
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import CustomPagination from "components/CustomPagination/CustomPagination";
import { Loading } from "features/common";
import CRMTable from "@metacrm/metacrm-material-ui/dist/Table";
import { Box, useTheme } from "@mui/material";
import { map, upperFirst } from "lodash-es";
import { useIntl } from "react-intl";
import useMarketModule from "hooks/useMarketModule";
import {
  Button,
  VARIANT,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import { StyledColumnTitle } from "./Referral.styles";
import _ from "lodash";
import { alertServerError, getShortAddress } from "features/helpers/utils";
import { useOutletContext } from "react-router";
import { useDispatch } from "react-redux";
import { apiUrl } from "features/configure";
import axios from "axios";
import { getUserAvatarIcon } from "features/metadesk/utils";
import { exportCSV, formatCSV } from "features/helpers/utils";
import HoverImage from "../campaign/settings/component/hoverImage";
import useNavigateEntity from "hooks/useNavigateEntity";
import { InputField, SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";

export default function ReferralDetail() {
  const { referralConnected, attributionConnect } = useMarketModule();
  const [entityName, isCustomDomain] = useOutletContext();
  const { navigateEntity } = useNavigateEntity();
  const [isLoading, setIsLoading] = useState(false);
  const [referralData, setReferralData] = useState({});
  const [order, setOrder] = useState("desc");
  const [sortBy, setSortBy] = useState("transactionReferred");
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const PAGE_COUNT = 20;
  const rowData = referralData?.data || [];
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const getAPIData = async () => {
    try {
      setIsLoading(true);
      let query = {
        page,
        sortBy,
        search,
        order: order == "desc" ? -1 : 1,
      };
      const { data, headers } = await axios.get(
        `${apiUrl}/api/referral/${entityName}/program/detail`,
        { params: query }
      );
      setTotalCount(_.get(headers, "x-total-count", 0));
      setReferralData(data);
      return data;
    } catch (err) {
      console.log("err: ", err);
      dispatch(alertServerError(err));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    if (referralConnected) {
      await getAPIData();
    }
  }, [page, sortBy, order, referralConnected]);

  const headerContent = (content) => (
    <Box height="30px" my="8px" display="flex" alignItems="center">
      <Box
        sx={{
          fontSize: "24px",
          fontWeight: 700,
          color: "#383538",
        }}
      >
        {content || "-"}
      </Box>
    </Box>
  );

  const headCells = [
    {
      show: true,
      disabledSort: true,
      id: "linkCreated",
      label: (
        <Box display="flex" flexDirection="column">
          <Box className="startRow">
            <StyledColumnTitle>
              {formatMessage({
                id: "referralAnalysis.columnName.linkCreated.title",
              })}
            </StyledColumnTitle>

            <IconTooltip
              customColor="#7B61FF"
              content={formatMessage({
                id: "referralAnalysis.columnName.linkCreated.tooltip",
              })}
            />
          </Box>
          {headerContent(_.get(referralData, "total.linkCreated"))}
        </Box>
      ),
    },
    {
      show: true,
      id: "linkClick",
      label: (
        <Box display="flex" flexDirection="column">
          <Box className="startRow">
            <StyledColumnTitle>
              {formatMessage({
                id: "referralAnalysis.columnName.linkClick.title",
              })}
            </StyledColumnTitle>

            <IconTooltip
              customColor="#7B61FF"
              content={formatMessage({
                id: "referralAnalysis.columnName.linkClick.tooltip",
              })}
            />
          </Box>
          {headerContent(_.get(referralData, "total.linkClick"))}
        </Box>
      ),
    },
    {
      show: true,
      id: "walletConnected",
      label: (
        <Box display="flex" flexDirection="column">
          <Box className="startRow">
            <StyledColumnTitle>
              {formatMessage({
                id: "referralAnalysis.columnName.walletConnected.title",
              })}
            </StyledColumnTitle>

            <IconTooltip
              customColor="#7B61FF"
              content={formatMessage({
                id: "referralAnalysis.columnName.walletConnected.tooltip",
              })}
            />
          </Box>
          {headerContent(_.get(referralData, "total.walletConnected"))}
        </Box>
      ),
    },
    {
      show: true,
      id: "transactionReferred",
      label: (
        <Box display="flex" flexDirection="column">
          <Box className="startRow">
            <StyledColumnTitle>
              {formatMessage({
                id: "referralAnalysis.columnName.transactionReferred.title",
              })}
            </StyledColumnTitle>

            <IconTooltip
              customColor="#7B61FF"
              content={formatMessage({
                id: "referralAnalysis.columnName.transactionReferred.tooltip",
              })}
            />
          </Box>
          {headerContent(_.get(referralData, "total.transactionReferred"))}
        </Box>
      ),
    },
  ];

  const renderContents = (row) => {
    const contents = [
      <Box slot="linkCreated" className="startRow">
        <img
          src={require(`assets/img/${getUserAvatarIcon(row.address, theme)}`)}
          className="icon"
        />
        {getShortAddress(row.address)}
      </Box>,
      <Box slot="linkClick" sx={{ maxWidth: "260px" }} className="wordBreak">
        {row.linkClick || "--"}
      </Box>,
      <Box slot="walletConnected"> {row.walletConnected || "--"}</Box>,
      <Box slot="transactionReferred">{row.transactionReferred || "--"}</Box>,
    ];
    return contents;
  };

  const exportToCSV = async () => {
    const exportResult = await axios.get(
      `${apiUrl}/api/referral/${entityName}/program/detail?dump=1`
    );
    exportCSV(exportResult.data, "MetaCRM_Referrals");
  };

  return (
    <Box position={"relative"}>
      <Loading open={isLoading} fullScreen={false} white />
      <Box className="title">
        {formatMessage({
          id: "referralAnalysis.header.title",
        })}
      </Box>
      <Box
        className="betweenRow"
        mt="24px"
        mb="9px"
        bgcolor={"white"}
        p="8px"
        borderRadius={"4px"}
      >
        <InputField
          size={SIZE.MEDIUM}
          placeholder={"Search for Link Created"}
          onChange={(e, value) => setSearch(e.target.value)}
          value={search}
          onBlur={() => {
            getAPIData();
          }}
          onEnter={() => {
            getAPIData();
          }}
          type="search"
          style={{ width: "400px" }}
        />
        <Button
          variant={VARIANT.OUTLINED}
          disabled={_.isEmpty(rowData)}
          onClick={exportToCSV}
          size={SIZE.MEDIUM}
        >
          {formatMessage({
            id: "global.btn.download",
          })}
        </Button>
      </Box>
      {attributionConnect ? (
        <Box>
          <div
            style={{
              position: "relative",
              height: "calc(100vh - 260px)",
              overflow: "auto",
            }}
          >
            <CRMTable
              rowData={rowData}
              headers={headCells}
              contents={map(rowData, (user, i) => renderContents(user))}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={sortBy}
              noDataComponent={
                <Box
                  style={{
                    backgroundColor: referralConnected
                      ? "transparent"
                      : "white",
                    height: "50vh",
                    margin: "-16px",
                  }}
                >
                  {referralConnected ? (
                    <Box className="startRow" color="#7B61FF">
                      <i className="meta-crm-icon-ic_info font-size-16 mgr4" />
                      <Box mr={"4px"}>
                        If the value stays at '0', please check if there were
                        any errors in your steps.{" "}
                      </Box>
                      <a
                        className="linkWithLine"
                        href="https://metacrm.gitbook.io/metacrm/products/metacrm-for-marketing/referrals"
                        target="_blank"
                      >
                        Referrals Tutorial
                      </a>
                    </Box>
                  ) : (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      height="100%"
                    >
                      <Box
                        component="img"
                        src={require("assets/img/img_customization.png")}
                        mb="20px"
                        maxWidth="180px"
                      />
                      <Box
                        width="100%"
                        sx={{ textAlign: "center", maxWidth: "200px" }}
                      >
                        {formatMessage({
                          id: "analytics.referral.settings.enableState",
                        })}
                      </Box>
                      <Button
                        onClick={() => {
                          navigateEntity("/analytics/settings/referral/create");
                        }}
                        style={{ marginTop: "16px" }}
                      >
                        Go to Create
                      </Button>
                    </Box>
                  )}
                </Box>
              }
            />
          </div>
          <CustomPagination
            shape="rounded"
            PAGE_COUNT={PAGE_COUNT}
            count={totalCount}
            page={page}
            setPage={(v) => setPage(v)}
          />
        </Box>
      ) : (
        <HoverImage
          originalImg={require("assets/img/referralDetailPlaceHolder.jpg")}
          hoverShowImg={require("assets/img/connect_discord.svg").default}
          hoverShowText={formatMessage({
            id: "analytics.referral.performance.disableState",
          })}
          onClick={() => {
            navigateEntity("/analytics/settings/referral/create");
          }}
        ></HoverImage>
      )}
    </Box>
  );
}

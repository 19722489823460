import React, { useState } from "react";
import { Button, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import UserInfoRoot from "features/metadesk/UserInfoRoot";
import { useFetchUserWalletInfo } from "features/metadesk/redux/fetchUserWalletInfo";

import { Loading } from "features/common";
import _ from "lodash";
import {
  StyledSearchAddressInfo,
  StyledSearchContainer,
  StyledSearchBar,
  StyledSearchContentContainer,
} from "./SearchAddressInfo.styles";
import { useIntl } from "react-intl";
import { isAddress } from "features/helpers/utils";

const SearchAddressInfo = ({ computer, renderUserInfo, entityName }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedUserAddress, setSelectedUserAddress] = useState("");
  const { searchUserWalletInfos } = useFetchUserWalletInfo();
  const { formatMessage } = useIntl();

  const userWalletInfo =
    _.get(searchUserWalletInfos, `[${selectedUserAddress}]`, {}) || {};

  const handleSearch = () => {
    setSelectedUserAddress(searchText);
    setSearchText("");
  };

  const handleKey = (event) => {
    if (event.keyCode == 13 && !event.shiftKey) {
      if (isAddress(searchText)) {
        handleSearch();
      }
    }
  };

  const handleSetSearchText = (value) => {
    if (!value || value.trim().length == 0) {
      value = value.trim();
    }
    setSearchText(value);
  };

  return (
    <StyledSearchAddressInfo>
      <StyledSearchContainer>
        <StyledSearchBar
          outline
          fullWidth
          placeholder={formatMessage({ id: "ticketDrawer.search.placeholder" })}
          onKeyDown={handleKey}
          value={searchText}
          onChange={(e) => {
            handleSetSearchText(e.target.value);
          }}
          startAdornment={
            <i className="meta-crm-icon-ic_search font-size-20" />
          }
          endAdornment={
            <Button
              size={SIZE.XS}
              disabled={!searchText || !isAddress(searchText)}
              onClick={handleSearch}
            >
              {formatMessage({ id: "global.btn.search" })}
            </Button>
          }
        />
      </StyledSearchContainer>
      <StyledSearchContentContainer>
        <Loading
          open={_.isEmpty(userWalletInfo) && selectedUserAddress}
          text={formatMessage({ id: "global.loading.search" })}
          fullScreen={false}
        />
        <div hidden={_.isEmpty(userWalletInfo)}>
          <UserInfoRoot
            selectedUserAddress={selectedUserAddress}
            entityName={entityName}
            isSearch
          />
        </div>
      </StyledSearchContentContainer>
    </StyledSearchAddressInfo>
  );
};

export default SearchAddressInfo;

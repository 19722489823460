import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { COLOR } from "@metacrm/metacrm-material-ui/dist/Button";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "features/common/redux/actions";
import { get } from "lodash-es";
import {
  selectServiceModule,
  selectWidgetModule,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";

import { apiUrl } from "features/configure";
import Box from "@mui/material/Box";
import axios from "axios";
import { useIntl } from "react-intl";
import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import { StyledTabContentContainer } from "./WidgetSetting.styles";
import WidgetLauncher from "./WidgetLauncher";
import WidgetLogo from "./WidgetLogo";
import WidgetTheme from "./WidgetTheme";
import WidgetTicketType from "./WidgetTicketType";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import useGetPagePermission from "hooks/useGetPagePermission";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import useNavigateEntity from "hooks/useNavigateEntity";
import { parseCustomDomainUrl } from "features/helpers/utils";

const WidgetSetting = ({}) => {
  const widgetModule = useSelector(selectWidgetModule);

  const { multiDomain } = widgetModule;

  const { formatMessage } = useIntl();
  const [entityName, isCustomDomain] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [widgetItemInfo, setWidgetItemInfo] = useState({});
  const dispatch = useDispatch();
  const theme = useTheme();
  const { widgetId } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [isDirtyNow, setIsDirtyNow] = useState(false);
  const confirm = useConfirm();

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("customization");

  const handleTabChange = (event, newValue) => {
    if (isDirtyNow && canEdit) {
      confirm({
        title: formatMessage({
          id: "popup.saveChange.title",
        }),
        content: formatMessage(
          {
            id: "popup.changeNotSaved.description",
          },
          { br: <br /> }
        ),
        confirmText: formatMessage({
          id: "global.btn.confirm",
        }),
        cancelText: formatMessage({
          id: "Cancel",
        }),
      })
        .then(() => {
          setTabValue(newValue);
        })
        .catch(() => {});
    } else {
      setTabValue(newValue);
    }
  };

  const handleSetDirty = (value) => {
    setIsDirtyNow(value);
  };

  const handleFetchWidgetDetail = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${apiUrl}/api/manage/widgetConfig/${entityName}?widgetId=${widgetId}`
      );
      setWidgetItemInfo(data);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchWidgetDetail();
  }, []);

  const { navigate } = useNavigateEntity();
  function goBack() {
    navigate(-1);
  }

  const tabs = useMemo(
    () => [
      {
        _id: "Launcher Icon",
        label: formatMessage({
          id: "widgetCustomization.tabName.launcherIcon",
        }),
        content: (
          <StyledTabContentContainer>
            <WidgetLauncher
              goBack={goBack}
              widgetItemInfo={widgetItemInfo}
              onSetDirty={handleSetDirty}
              onRefetch={handleFetchWidgetDetail}
            />
          </StyledTabContentContainer>
        ),
      },
      {
        _id: "Custom Logo",
        label: formatMessage({
          id: "widgetCustomization.tabName.logo",
        }),
        content: (
          <StyledTabContentContainer>
            <WidgetLogo
              goBack={goBack}
              widgetItemInfo={widgetItemInfo}
              onSetDirty={handleSetDirty}
              onRefetch={handleFetchWidgetDetail}
            />
          </StyledTabContentContainer>
        ),
      },
      {
        _id: "Widget Appearance",
        label: formatMessage({
          id: "widgetCustomization.tabName.appearance",
        }),
        content: (
          <StyledTabContentContainer>
            <WidgetTheme
              goBack={goBack}
              widgetItemInfo={widgetItemInfo}
              onSetDirty={handleSetDirty}
              onRefetch={handleFetchWidgetDetail}
            />
          </StyledTabContentContainer>
        ),
      },

      {
        _id: "Ticket Type",
        label: formatMessage({
          id: "widgetCustomization.tabName.ticketType",
        }),
        content: (
          <StyledTabContentContainer>
            <WidgetTicketType
              goBack={goBack}
              widgetItemInfo={widgetItemInfo}
              onSetDirty={handleSetDirty}
              onRefetch={handleFetchWidgetDetail}
            />
          </StyledTabContentContainer>
        ),
      },
    ],
    [widgetItemInfo]
  );

  return (
    <Box
      sx={{
        paddingTop: "130px",
        paddingLeft: "60px",
        paddingRight: "60px",
        display: "flex",
        minHeight: "calc(100vh - 30px)",
        justifyContent: "center",
        background: theme.customColors.white,
      }}
    >
      {isLoading ? (
        <Box height={"80vh"}>
          <LoadingComponent />
        </Box>
      ) : (
        <Box width="100%" maxWidth={"1440px"}>
          <Box
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              color: theme.customColors.grey[800],
              marginBottom: 1,
            }}
          >
            {widgetItemInfo.name}
          </Box>

          <Box
            sx={{
              color: theme.customColors.grey[600],
              marginBottom: "32px",
            }}
          >
            {formatMessage({ id: "widgetCustomization.launcher.description" })}
          </Box>
          <Tabs
            tabs={tabs}
            color={COLOR.SECONDARY}
            variant="scrollable"
            allowScrollButtonsMobile
            value={tabValue}
            onChange={handleTabChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default WidgetSetting;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";

import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";

const getChainNameById = (chainList, id) => {
  const chain = chainList.find((chain) => chain.id === id);
  return chain ? chain.name : null; // 如果找到則返回 name，否則返回 null
};

const ObjectiveItem = ({ objectInfo }) => {
  const theme = useTheme();
  const chainList = useSelector(selectEntityChainList);

  return (
    <Box sx={{ background: theme.customColors.white, borderRadius: "8px" }}>
      <Box
        sx={{
          padding: "20px",
          fontWeight: "700",
          color: theme.customColors.grey[700],
          borderBottom: `1px solid ${theme.customColors.grey[300]}`,
        }}
      >
        Contract Interaction - {objectInfo?.eventName}
      </Box>
      <Box sx={{ padding: "16px 20px 28px" }}>
        <Box component={Grid} container>
          <Box component={Grid} item xs={3}>
            <Box
              sx={{
                fontSize: "12px",
                color: theme.customColors.grey[600],
                marginBottom: "8px",
              }}
            >
              Chain
            </Box>
            <Box
              sx={{
                backgroundColor: "#F3BA2F",
                color: theme.customColors.white,
                padding: "4px 8px",
                borderRadius: "20px",
                display: "inline-block",
              }}
            >
              {getChainNameById(chainList, objectInfo?.chainId)}
            </Box>
          </Box>
          <Box component={Grid} item xs={6}>
            <Box
              sx={{
                fontSize: "12px",
                color: theme.customColors.grey[600],
                marginBottom: "8px",
              }}
            >
              Contract Address
            </Box>
            <Box sx={{ color: theme.customColors.black }}>
              {objectInfo?.contractAddress}
            </Box>
          </Box>
          <Box component={Grid} item xs={3}>
            <Box
              sx={{
                fontSize: "12px",
                color: theme.customColors.grey[600],
                marginBottom: "8px",
              }}
            >
              Target Event
            </Box>

            <Box sx={{ color: theme.customColors.black }}>
              {objectInfo?.eventName}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ObjectiveItem;

import React, { useRef, useState, useEffect, useMemo } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import _ from "lodash";
import { Box } from "@mui/material";
import {
  useFetchUserList,
  useFetchTags,
  useFetchEntityConfig,
} from "../redux/hooks";
import CustomTable from "components/CustomTable/CustomTable";
import UserTag from "../components/UserTag";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import {
  Button,
  COLOR,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import ImportDataPopover from "../components/ImportList/ImportDataPopover";
import BigNumber from "bignumber.js";
import { useDispatch } from "react-redux";
import { alertServerError, parseCustomDomainUrl } from "features/helpers/utils";
import {
  StyledStartRowTitleInsideContainer,
  StyledListFunctionRow,
} from "./ImportUserList.styles";
import CustomPagination from "components/CustomPagination/CustomPagination";
import Address from "../components/Address";
import TableMenu from "components/CustomTable/TableMenu";
import axios from "axios";
import { apiUrl } from "features/configure";
import RemoveConfirm from "components/RemoveConfirm/RemoveConfirm";
import { StyledTableMenuButton } from "components/CustomTable/styles";
import ExportModal from "../components/ImportList/ExportModal";
import TokensWraper from "../components/Icons/TokensWraper";
import { MetaCRMTag } from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";

import { USER_FILTER_INITIAL_STATE } from "../redux/fetchUserList";
import AssignTagsModal from "../components/ImportList/AssignTagsModal";
import UnassignTagsModal from "../components/ImportList/UnassignTagsModal";
import moment from "moment";
import useGetPagePermission from "hooks/useGetPagePermission";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";
import { Menu } from "@metacrm/metacrm-material-ui/dist/Menu";
import { isEmpty, startCase } from "lodash-es";
import { selectRemindBarShow } from "../redux/globalState/globalState.selector";

const kMapping = {
  "Wallet Value": "walletValue",
  "NFT Amount": "nftAmount",
  "Last Engagement": "lastEngagement.date",
  Tags: "tags.length",
};

const ImportUserList = () => {
  const { entityConfig } = useFetchEntityConfig();
  const [entityName, isCustomDomain] = useOutletContext();
  const remindBarShow = useSelector(selectRemindBarShow);
  const navigate = useNavigate();
  const [openExport, setOpenExport] = useState(false);
  const [openAssignTags, setOpenAssignTags] = useState(false);
  const [openUnassignTags, setOpenUnassignTags] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { tags } = useFetchTags();
  const tagOptions = _.get(tags, `[${entityName}]`, []);
  const [selectedRowUserData, setSelectedRowUserData] = useState([]);
  const [open, setOpen] = useState(false);
  const ecosystemName = useSelector(selectEcosystemName);

  const PAGE_COUNT = 20;
  const theme = useTheme();
  const {
    userSource,
    userSourceCount,
    fetchUserListPending,
    userList,
    fetchUserAdminList,
    userListCount,
    updateUserListFilter,
    userListFilter,
    fetchUserSource,
    csvHeaders,
    userHeaderItems,
  } = useFetchUserList();

  const page = _.get(userListFilter, "page", 1);
  const orderData = _.get(userListFilter, "orderData");
  const searchSource = _.get(userListFilter, "searchSource");
  const search = _.get(userListFilter, "search");
  const [searchText, setSearchText] = useState(search);
  const searchTags = _.get(userListFilter, "searchTags", []);

  const sourceList = [
    // { label: "All", value: "" },
    ..._.chain(userSource)
      .groupBy("indicator")
      .keys()
      .map((o) => {
        const mappingLabel = {
          metadesk: "Ticket",
          csv: "CSV Import",
          attribution: "Attribution Link",
          contract: "Smart Contract Import",
        };
        return {
          label: mappingLabel[o],
          value: o,
        };
      })
      .value(),
  ];

  const platFormList = ["Discord", "X", "Email"];
  const lastEngagement = [
    "ticketChat",
    "campaignClick",
    "campaignConversion",
    "widgetNotifRead",
    "csvImport",
    "contractImport",
  ];

  const selectedCount = selectedRowUserData.length;
  const [tabs, setTabs] = useState([]);

  const sourceFilterNumber = useMemo(
    () => userListFilter.searchSource.length,
    [userListFilter.searchSource]
  );

  const platformFilterNumber = useMemo(
    () => userListFilter.platform.length,
    [userListFilter.platform]
  );

  const lastEngagementFilterNumber = useMemo(
    () => userListFilter.lastEngagement.length,
    [userListFilter.lastEngagement]
  );

  const searchTagsFilterNumber = useMemo(
    () =>
      userListFilter.searchTags.includes("")
        ? 0
        : userListFilter.searchTags.length,
    [userListFilter.searchTags]
  );

  const topFilterNumber = useMemo(
    () =>
      sourceFilterNumber +
      searchTagsFilterNumber +
      platformFilterNumber +
      lastEngagementFilterNumber,
    [
      sourceFilterNumber,
      searchTagsFilterNumber,
      platformFilterNumber,
      lastEngagementFilterNumber,
    ]
  );

  const handleChangeCheckbox = (e, filterKey) => {
    e.keepOpen = true;
    if (e.checked) {
      // 要是是選中
      if (e.value === "") {
        // 選全選的話(空字串) 就只要設定全選進入結果就好
        updateUserListFilter({
          ...userListFilter,
          page: 1,
          [filterKey]: [],
        });
      } else {
        // 選全選以外的 就要把空字串過去料 並把舊的值 新的值都放進去
        const newFilter = userListFilter[filterKey].filter(
          (value) => value !== ""
        );
        updateUserListFilter({
          ...userListFilter,
          page: 1,
          [filterKey]: [...newFilter, e.value],
        });
      }
    } else {
      // 要是是取消
      const newValue = userListFilter[filterKey].filter(
        (value) => value !== e.value
      );
      updateUserListFilter({
        ...userListFilter,
        page: 1,
        [filterKey]: newValue,
      });
    }
  };

  const itemList = [
    {
      children: <>User IDs</>,
      filterNumber: platformFilterNumber,
      subMenu: [
        ...platFormList.map((item) => ({
          children: <>{item}</>,
          value: item,
          type: "checkbox",
          backgroundColor: item.color,
          checked: userListFilter.platform.some(
            (platform) => platform === item
          ),
          func: (e) => handleChangeCheckbox(e, "platform"),
        })),
      ],
    },
    {
      children: <>Tag</>,
      filterNumber: searchTagsFilterNumber,
      subMenu: [
        {
          children: <>All</>,
          value: "",
          type: "checkbox",
          checked: isEmpty(userListFilter.searchTags),
          func: (e) => handleChangeCheckbox(e, "searchTags"),
        },
        ...tagOptions.map((item) => ({
          children: <>{item.value}</>,
          value: item._id,
          type: "checkbox",
          backgroundColor: item.color,
          checked: userListFilter.searchTags.some(
            (tagValue) => tagValue === item._id
          ),
          func: (e) => handleChangeCheckbox(e, "searchTags"),
        })),
      ],
    },
    {
      children: <>Source</>,
      filterNumber: sourceFilterNumber,
      subMenu: [
        ...sourceList.map((item) => ({
          children: <>{item.label}</>,
          value: item.value,
          type: "checkbox",
          checked: userListFilter.searchSource.some(
            (source) => source === item.value
          ),
          func: (e) => handleChangeCheckbox(e, "searchSource"),
        })),
      ],
    },
    {
      children: <>Last Engagement</>,
      filterNumber: lastEngagementFilterNumber,
      subMenu: [
        ...lastEngagement.map((item) => ({
          children: (
            <>{item === "csvImport" ? "CSV Import" : startCase(item)}</>
          ),
          value: item,
          type: "checkbox",
          checked: userListFilter.lastEngagement.some(
            (lastEngagement) => lastEngagement === item
          ),
          func: (e) => handleChangeCheckbox(e, "lastEngagement"),
        })),
      ],
    },
  ];
  const handleOpenImportPopover = (e) => {
    setOpen(true);
  };

  const handleCloseImportPopover = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleFetchUserSource();
  }, []);

  const handleFetchUserSource = () => {
    fetchUserSource({ entityName: entityName });
    onFetchUserAdminList();
  };
  const abortControllerRef = useRef(new AbortController());

  const onFetchUserAdminList = () => {
    abortControllerRef.current.abort(); // Cancel any ongoing request
    abortControllerRef.current = new AbortController(); // Create a new AbortController
    fetchUserAdminList({
      entityName,
      from: "all",
      filter: userListFilter,
      search,
      searchSource,
      searchTags,
      signal: abortControllerRef.current.signal,
    });
  };
  useEffect(() => {
    setLoading(false);
  }, [userList]);

  useEffect(() => {
    onFetchUserAdminList();
  }, [userListFilter]);

  const handleSort = (header, direction) => {
    const by = header || orderData.sort;
    const dir =
      orderData.sort != by ? orderData.order : direction || orderData.order;
    updateUserListFilter({ orderData: { sort: by, order: dir } });
  };

  const renderSourcePopup = (user) => {
    const latestSource = user.source;

    const sourceMapping = {
      csv: "CSV Import",
      contract: "Smart Contract Import",
      metadesk: "Ticket",
      attribution: "Attribution",
    };

    return (
      <div slot="sources" style={{ textAlign: "left", fontSize: "12px" }}>
        <Box color="#555555"> {sourceMapping[latestSource?.type]}</Box>
        <Box color="#A5A5A5">
          {moment(latestSource?.date).format("MMM DD,YYYY hh:mmA")}
        </Box>
      </div>
    );
  };

  const selectedUserData = selectedRowUserData;

  const deleteSomeUsers = async () => {
    try {
      if (selectedCount == 0) return;
      const selectedUserData = _.map(selectedRowUserData, "_id");

      await axios.post(`${apiUrl}/api/userSource/${entityName}/deleteUsers`, {
        userIds: selectedUserData,
      });

      setSelectedRowUserData([]);
      onFetchUserAdminList();
      handleFetchUserSource();
    } catch (err) {
      dispatch(alertServerError(err));
    }
  };

  const renderContents = (user) => {
    let contents = [
      <div slot="userIdentity">
        <Address user={user} showExtra={true} />
      </div>,
      <div slot="walletValue">
        {user?.walletValue
          ? "$" + new BigNumber(user?.walletValue).toFormat(0)
          : "-"}
      </div>,
      <div slot="nftAmount">{user?.nftAmount || "-"}</div>,
      <div slot="tags" onClick={(e) => e.stopPropagation()}>
        <UserTag
          entityName={entityName}
          width={"fit-content"}
          minWidth={0}
          showNumber={1}
          userInfo={{
            _id: user._id,
            address: user.address,
            tags: _.get(user, "tags", []),
          }}
        />
      </div>,
      <div slot="discordHandle">
        {_.get(user, "mostRecentDiscordHandle", "")}
      </div>,
      <div slot="protocols">
        <TokensWraper
          tokens={_.get(user, "protocols", [])}
          size={"24px"}
          showNumber={5}
          marginLeft="-8px"
        />
      </div>,
      <div slot="tokens">
        <TokensWraper
          tokens={_.get(user, "tokens", [])}
          size={"24px"}
          showNumber={5}
          marginLeft="-8px"
        />
      </div>,
      <div slot="tickets" className="startRow">
        {_.get(user, "tickets", 0) > 0 ? (
          <MetaCRMTag option={{ label: user.tickets, color: "#F7F1FE" }} />
        ) : (
          ""
        )}
      </div>,
      <div slot="twitterHandle">
        {_.get(user, "mostRecentTwitterHandle", "")}
      </div>,
      <div slot="email">{_.get(user, "email", "")}</div>,
      <div slot="lastEngagement">
        <Box color="#555" fontSize="12px">
          {user.lastEngagement.type === "csvImport"
            ? "CSV Import"
            : startCase(user.lastEngagement.type)}
        </Box>
        <Box color="#A5A5A5" fontSize="12px">
          {user.lastEngagement.date
            ? moment(user.lastEngagement.date).format("MMM DD,YYYY hh:mmA")
            : "-"}
        </Box>
      </div>,
    ];
    contents = [
      contents[0],
      renderSourcePopup(user),
      ...contents.slice(1),
      ..._.map(csvHeaders, (h) => {
        const csvValue = _.find(user.csvFields, { header: h._id });
        return (
          <div slot={h._id} style={{ padding: "0 8px" }}>
            {csvValue ? _.truncate(csvValue.value) : ""}
          </div>
        );
      }),
    ];
    return contents;
  };

  const pagePermission = useGetPagePermission();
  const { canEdit, readonly } = pagePermission("customerList");

  const selectedMenu = canEdit
    ? [
        { label: `Selected: ${selectedCount}` },
        {
          label: (
            <div className="startRow">
              <i className="meta-crm-icon-ic_tag font-size-18 mgr4" />
              Assign Tag
            </div>
          ),
          onClick: () => {
            setOpenAssignTags("selected");
          },
        },
        {
          label: (
            <div className="startRow">
              <i className="meta-crm-icon-ic_unassignTag font-size-18 mgr4" />
              Unassign Tag
            </div>
          ),
          onClick: () => {
            setOpenUnassignTags("selected");
          },
        },
        {
          label: (
            <div className="startRow">
              <i className="meta-crm-icon-ic-download-1 font-size-18 mgr4" />
              Export
            </div>
          ),
          onClick: () => {
            setOpenExport(true);
          },
        },
        {
          button: (
            <RemoveConfirm
              onRemove={deleteSomeUsers}
              removeComponent={
                <StyledTableMenuButton
                  color={COLOR.SECONDARY}
                  size={SIZE.SMALL}
                  className="tabMenuButton"
                  startIcon={
                    <i className="meta-crm-icon-ic_trash font-size-14" />
                  }
                >
                  Delete
                </StyledTableMenuButton>
              }
            />
          ),
        },
      ]
    : [
        { label: `Selected: ${selectedCount}` },
        {
          label: (
            <div className="startRow">
              <i className="meta-crm-icon-ic-download-1 font-size-18 mgr4" />
              Export
            </div>
          ),
          onClick: () => {
            setOpenExport(true);
          },
        },
      ];
  const isLoading = fetchUserListPending || loading;

  const getUsersIntersectionTag = () => {
    const data = _.intersectionBy(userList, selectedRowUserData, "_id");
    const intersectionTags = _.intersectionBy(..._.map(data, "tags"), "_id");
    return intersectionTags;
  };

  const renderImportButton = () => {
    // if (ecosystemName === "EVM") {
    //   return (
    //     <Button color={COLOR.SECONDARY} onClick={handleOpenImportPopover}>
    //       + import Data
    //     </Button>
    //   );
    // } else {
    return null;
    // }
  };

  return (
    <>
      <div className="betweenRow">
        <div>
          <div className="title">
            <StyledStartRowTitleInsideContainer>
              Customer List
            </StyledStartRowTitleInsideContainer>
          </div>
        </div>
        {canEdit && renderImportButton()}
      </div>
      {canEdit && (
        <ImportDataPopover
          entityName={entityName}
          onClose={handleCloseImportPopover}
          open={open}
          supportExtension={[".csv", ".xlsx"]}
          onFetchUserSource={handleFetchUserSource}
        />
      )}

      <StyledListFunctionRow>
        <div style={{ marginRight: 8 }}>
          <InputField
            size={SIZE.MEDIUM}
            onEnter={(v, e) => e.target.blur()}
            placeholder="Search list by address..."
            startAdornment={
              <i
                className="meta-crm-icon-ic_search font-size-16"
                style={{ marginRight: 5, color: "#A5A5A5" }}
              />
            }
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onBlur={() => {
              updateUserListFilter({ search: searchText, page: 1 });
              setSelectedRowUserData([]);
            }}
          />
        </div>

        <Box mr={1}>
          <Menu
            overflow="auto"
            position="initial"
            itemList={itemList}
            isFilter
            topFilterNumber={topFilterNumber}
            zIndex={500}
          />
        </Box>

        <Button
          variant={VARIANT.TEXT}
          style={{ padding: 0, marginLeft: 8 }}
          size={SIZE.SMALL}
          onClick={() => {
            updateUserListFilter(USER_FILTER_INITIAL_STATE.userListFilter);
            setSelectedRowUserData([]);
            setSearchText("");
          }}
        >
          Clear all filters
        </Button>
      </StyledListFunctionRow>
      <div style={{ position: "relative" }}>
        <CustomTable
          checkbox
          containerStyle={{
            height: `calc(70vh - ${remindBarShow ? 60 : 0}px)`,
          }}
          loading={isLoading}
          rowData={userList}
          selectedRowData={selectedRowUserData}
          setSelectedRowData={setSelectedRowUserData}
          noDataTitle="No User"
          canChangeOrder={true}
          sortDataFunction={handleSort}
          sortBy={orderData.sort}
          order={_.get(orderData, "order")}
          storageKey="customerListHeaders"
          sortMapping={kMapping}
          headers={userHeaderItems}
          rowSelected={(rowIndex) => {
            navigate(
              parseCustomDomainUrl(
                isCustomDomain,
                entityName,
                `/customers/user/${rowIndex}`
              )
            );
          }}
          contents={_.map(userList, (user, i) => {
            return renderContents(user);
          })}
          noDataComponent={
            _.get(entityConfig, "modules.marketing") &&
            userListCount === 0 && (
              <div>
                <img
                  src={require("assets/img/emptyToImport.svg").default}
                  style={{ width: 254 }}
                />
                <div className="title">It seems a little empty out here!</div>
                {canEdit && (
                  <>
                    <div
                      className="timePeriod"
                      style={{ marginTop: 6, marginBottom: 13 }}
                    >
                      Why not import your first customer and embark on this
                      exciting journey?
                    </div>

                    {renderImportButton()}
                  </>
                )}
              </div>
            )
          }
        />
        <TableMenu show={selectedCount > 0} tabs={selectedMenu} />
      </div>
      <CustomPagination
        shape="rounded"
        PAGE_COUNT={PAGE_COUNT}
        count={userListCount}
        page={page}
        setPage={(v) => updateUserListFilter({ page: v })}
      />
      <ExportModal
        open={openExport}
        onClose={() => setOpenExport(false)}
        dataset={_.find(userSource, { _id: "all" })}
        entityName={entityName}
        choose={_.map(selectedUserData, "_id")}
      />
      {canEdit && (
        <>
          <AssignTagsModal
            open={openAssignTags}
            onClose={(isRefresh) => {
              setOpenAssignTags(false);
              if (isRefresh) onFetchUserAdminList();
            }}
            entityName={entityName}
            choose={_.map(selectedUserData, "_id")}
          />
          <UnassignTagsModal
            open={openUnassignTags}
            onClose={(isRefresh) => {
              setOpenUnassignTags(false);
              if (isRefresh) onFetchUserAdminList();
            }}
            entityName={entityName}
            choose={_.map(selectedUserData, "_id")}
            uniqTags={getUsersIntersectionTag()}
          />
        </>
      )}
    </>
  );
};

export default ImportUserList;

import { useBlocker, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import BasicModal from "features/metadesk/chat/BasicModal";
import { useIntl } from "react-intl";

function Prompt(props) {
  const navigate = useNavigate();
  const nextPath = useRef(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const when = props.when && !confirmed;
  const { formatMessage } = useIntl();
  const { message, title = formatMessage({ id: "popup.saveChange.title" }) } =
    props;

  const handleBlockedNavigation = (ev) => {
    // navigation not confirmed, save ev.retry and show prompt
    if (when) {
      nextPath.current = ev.nextLocation;
      setShowPrompt(true);
      return true;
    }
    return false;
  };

  const cancelNavigation = () => {
    setShowPrompt(false);
  };

  const confirmNavigation = () => {
    setShowPrompt(false);
    setConfirmed(true);
  };

  useBlocker(handleBlockedNavigation);

  useEffect(() => {
    if (confirmed && nextPath.current.pathname) {
      navigate(nextPath?.current?.pathname + nextPath?.current?.search);
    }
  }, [confirmed, nextPath]);

  return (
    <BasicModal
      open={showPrompt}
      onClose={cancelNavigation}
      modalTitle={title}
      modalContent={
        message ||
        formatMessage(
          { id: "popup.changeNotSaved.description" },
          { br: <br /> }
        )
      }
      confirmText={formatMessage({ id: "global.btn.leaveWithoutSaving" })}
      cancelText="Stay on Page"
      onHandleConfirm={confirmNavigation}
    />
  );
}

export default Prompt;
